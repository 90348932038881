import { useState } from 'react';
import { DataCardStack } from '~/components/Form/DataCardStack/DataCardStack';
import { Dialog } from '~/components/Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { GetViperAssetQuery, LifecycleEventType } from '~/graphql/generated/asset/graphql';
import {
  useCreateLifecycleEvent,
  useUpdateLifecycleEvent,
  useUpdateViperAsset,
} from '~/features/viper-asset/queries/viper-asset';
import {
  SaveError,
  joinErrorStates,
  withErrorHandling,
} from '~/components/Form/SaveError/SaveError';
import { useUpdateVlim } from '~/features/viper-asset/queries/data-retrieval';
import { useOverviewTab } from '../../hooks/useOverviewTab';
import { CreateFirmwareLogDialog } from '../../components/dialogs/CreateFirmwareLogDialog';

const dialogCopy = {
  [LifecycleEventType.Installation]: {
    title: 'viper-asset::create-dialog/installation/title',
    content: 'viper-asset::create-dialog/installation/content',
  },
  [LifecycleEventType.Uninstallation]: {
    title: 'viper-asset::create-dialog/uninstallation/title',
    content: 'viper-asset::create-dialog/uninstallation/content',
  },
  [LifecycleEventType.Commissioning]: {
    title: 'viper-asset::create-dialog/commissioning/title',
    content: 'viper-asset::create-dialog/commissioning/content',
  },
  [LifecycleEventType.Decommissioning]: {
    title: 'viper-asset::create-dialog/decommissioning/title',
    content: 'viper-asset::create-dialog/decommissioning/content',
  },
};

const isLifecycleEventType = (item: string | undefined): item is LifecycleEventType => {
  return [
    LifecycleEventType.Installation,
    LifecycleEventType.Commissioning,
    LifecycleEventType.Decommissioning,
    LifecycleEventType.Uninstallation,
  ].includes(item as LifecycleEventType);
};

interface OverviewTabProps {
  viperAsset: GetViperAssetQuery['viperAsset'];
}

export const OverviewTab = ({ viperAsset }: OverviewTabProps) => {
  const { t } = useTranslation('viper-asset');

  const {
    schema,
    optionsLoading,
    creating,
    editing,
    setEditing,
    showLifecycleDialog,
    hasHistory,
    resetAllForms,
    setShowLifecycleDialog,
    validate,
    showCreateFirmwareLogDialog,
    setShowCreateFirmwareLogDialog,
    id,
  } = useOverviewTab(viperAsset);

  const [createLifecycleEvent, createErrorState] = withErrorHandling(
    useCreateLifecycleEvent()
  );
  const [latestValues, setLatestValues] = useState<Record<string, unknown>>();
  const [saving, setSaving] = useState(false);
  const [savingSection, setSavingSection] = useState<string>();
  const [updateLifecycleEvent, updateLifecycleEventErrorState] = withErrorHandling(
    useUpdateLifecycleEvent()
  );
  const [updateViperAsset, updateViperAssetErrorState] = withErrorHandling(
    useUpdateViperAsset()
  );

  const [updateVlim, updateVlimErrorState] = withErrorHandling(useUpdateVlim());

  const onSave = async (values?: Record<string, unknown>) => {
    // If we show a dialog, the values get stored in the state so they're available
    // here. If we're not showing a dialog we have to pass the values directly, as
    // if we store them in the state they're not available until the next render.
    const theValues = values || latestValues;
    if (!theValues || !viperAsset) {
      return;
    }

    const variables = {
      lifecycleEventInput: {
        channelId:
          (theValues.channelId as string) ||
          (viperAsset.latestLifecycleEvent?.channel.id as string),
        viperAssetId: viperAsset.id,
        eventType: creating,
        dateOfLifecycleEvent: theValues.dateOfLifecycleEvent as string,
        personId: theValues.personId as string,
        notes: theValues.notes as string,
        installationTypeId: theValues.installationTypeId as string,
        decommissionReasonId: theValues.decommissionReasonId as string,
        uninstallStatusId: theValues.uninstallStatusId as string,
      },
    };

    // We don't want to send the current firmware revision ID to the backend
    delete theValues.currentFirmwareRevisionId;
    if (creating) {
      setSaving(true);
      setSavingSection(creating);
      try {
        await createLifecycleEvent({
          variables,
        });
      } finally {
        resetAllForms();
        setSaving(false);
      }
    }
    if (editing) {
      setSaving(true);
      setSavingSection(editing);
      try {
        switch (true) {
          case editing === 'automatic-polling':
            await updateVlim({
              variables: {
                serialNumber: viperAsset.serialNumber,
                vlimInput: {
                  pollingIpAddress: theValues.pollingIpAddress as string,
                  remoteAccess: theValues.remoteAccess as boolean,
                },
              },
            });
            break;
          case isLifecycleEventType(editing):
            // keep the type checker happy
            if (!viperAsset.latestLifecycleEvent) {
              throw new Error('Cannot edit non-existent event');
            }
            await updateLifecycleEvent({
              variables: {
                id: viperAsset.latestLifecycleEvent?.id,
                lifecycleEventInput: {
                  dateOfLifecycleEvent: { value: theValues.dateOfLifecycleEvent as string },
                  decommissionReasonId: theValues.decommissionReasonId as string,
                  installationTypeId: theValues.installationTypeId as string,
                  notes: theValues.notes as string,
                  personId: theValues.personId as string,
                  uninstallStatusId: theValues.uninstallStatusId as string,
                },
              },
            });
            break;
          default:
            // Remove form values populated from polling service
            delete theValues.lastPollingDate;
            delete theValues.nextPollingDate;

            await updateViperAsset({
              variables: {
                id,
                viperAssetInput: {
                  ...theValues,
                  maxIrCap:
                    theValues.maxIrCap !== undefined
                      ? (theValues.maxIrCap as number)
                      : undefined,
                  serialNumber:
                    theValues.serialNumber !== undefined
                      ? { value: theValues.serialNumber as string }
                      : undefined,
                  partId:
                    theValues.partId !== undefined
                      ? { value: theValues.partId as string }
                      : undefined,
                  customerId:
                    theValues.customerId !== undefined
                      ? { value: theValues.customerId as string }
                      : undefined,
                },
              },
            });
            break;
        }
      } finally {
        resetAllForms();
        setSaving(false);
      }
    }
  };

  return (
    <>
      <SaveError errorState={createErrorState}>{t(`error-saving/${savingSection}`)}</SaveError>
      <SaveError
        errorState={joinErrorStates(
          updateLifecycleEventErrorState,
          updateViperAssetErrorState,
          updateVlimErrorState
        )}
      >
        {t(`error-updating`)}
      </SaveError>
      <DataCardStack
        data={schema}
        loading={optionsLoading}
        translationKey='viper-asset'
        editing={editing || creating}
        setEditing={(section) => {
          setEditing(section);
        }}
        saving={Boolean(saving)}
        onCancel={resetAllForms}
        onSave={async (_, values) => {
          if (creating && hasHistory) {
            setLatestValues(values);
            setShowLifecycleDialog(true);
          } else {
            await onSave(values);
          }
        }}
        validate={validate}
      />
      <Dialog
        open={showLifecycleDialog}
        confirmText={t('viper::ok')}
        cancelText={t('viper::cancel')}
        onClose={() => setShowLifecycleDialog(false)}
        onConfirm={onSave}
        title={t(dialogCopy[creating as LifecycleEventType]?.title)}
        content={t(dialogCopy[creating as LifecycleEventType]?.content)}
      />
      <CreateFirmwareLogDialog
        open={showCreateFirmwareLogDialog}
        onClose={() => {
          setShowCreateFirmwareLogDialog(false);
        }}
        id={id}
        firmwareLogs={viperAsset?.firmwareUpdates}
      />
    </>
  );
};
