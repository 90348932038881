import { useMemo } from 'react';
import { CellLink } from '~/components/Table/components/CellLink';
import { Path } from '~/routes/paths';
import { useTranslation } from 'react-i18next';
import { PendingVLifeReportRow } from '~/features/v-life-reports/types';
import { usePendingVLifeReportWarnings } from '~/features/v-life-reports/hooks/usePendingVLifeReportWarnings';
import { useGetVLifeReportFormDefaults } from '~/features/v-life-reports/queries/v-life-reports';

interface ReportValidationWarningIconProps {
  row: PendingVLifeReportRow;
}

export const ReportValidationWarningIcon = ({ row }: ReportValidationWarningIconProps) => {
  const { t } = useTranslation('v-life-reports');

  const { id: channelId } = row;

  const { data, loading: loadingDefaults } = useGetVLifeReportFormDefaults(channelId);

  const { startDate, endDate } = useMemo(() => {
    let startDate = '';
    let endDate = '';
    if (data?.vlifeReportFormDefaults) {
      startDate = data.vlifeReportFormDefaults.reportPeriodStartDate ?? '';
      endDate = data.vlifeReportFormDefaults.reportPeriodEndDate ?? '';
    }
    return { startDate, endDate };
  }, [data]);

  const { warnings, loading: loadingWarnings } = usePendingVLifeReportWarnings(
    channelId,
    startDate,
    endDate
  );

  const warningMessages = !warnings.length
    ? undefined
    : warnings.map(({ warningMessage, id }) => (
        <>
          <span key={id}>{warningMessage}</span>
          <br />
        </>
      ));

  const loading = useMemo(
    () => loadingDefaults || loadingWarnings,
    [loadingWarnings, loadingDefaults]
  );

  return (
    <CellLink
      warning={warningMessages}
      loading={loading}
      path={Path.V_LIFE_REPORT_CREATE_PATH}
      param={{ channelId: row.id }}
      content={t('create')}
    />
  );
};
