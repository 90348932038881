import React from 'react';

import { Table } from '~/components/Table/Table';
import { Box, Button, Typography } from '@mui/material';
import { UpdateFirmwareLogDialog } from './dialogs/UpdateFirmwareLogDialog';
import { GetViperAssetQuery } from '~/graphql/generated/asset/graphql';
import { useFirmwareLog } from '../hooks/useFirmwareLog';
import { ACTION_EDIT } from '~/components/ActionMenu/ActionMenu';
import { CreateFirmwareLogDialog } from './dialogs/CreateFirmwareLogDialog';
import { useTranslation } from 'react-i18next';
import { LoadingSkeleton } from '~/components/LoadingSkeleton/LoadingSkeleton';
import { RBACPermissions } from '~/authorization/permissions';
import { IfPermitted } from '~/authorization/utils/IfPermitted';

const actionMenu = {
  label: 'actions',
  actionMenu: [ACTION_EDIT],
};

export const FirmwareLog: React.FC<{
  viperAsset: GetViperAssetQuery['viperAsset'];
}> = ({ viperAsset }) => {
  const { t } = useTranslation('viper-asset');
  const {
    handleFirmwareLogActionClick,
    showFirmwareLogDialog,
    setShowFirmwareLogDialog,
    showCreateFirmwareLogDialog,
    setShowCreateFirmwareLogDialog,
    firmwareLogs,
    firmwareLogsDetails,
    loading,
    id,
  } = useFirmwareLog(viperAsset);

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
        <Typography variant='h3'>{t('firmware-logs')}</Typography>
        <IfPermitted requiredPermissions={[RBACPermissions.VIPER_ASSET_WRITE]}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setShowCreateFirmwareLogDialog(true)}
          >
            {t('update-firmware')}
          </Button>
        </IfPermitted>
      </Box>
      <Table
        data={firmwareLogs}
        omittedKeys={['_id', '_actions']}
        translationKey={'viper-asset'}
        nestedData={firmwareLogsDetails}
        actionMenu={actionMenu}
        onActionClick={handleFirmwareLogActionClick}
        defaultOrderBy={t('viper-asset::firmware/date') ?? 'date'}
      />
      <UpdateFirmwareLogDialog
        open={!!showFirmwareLogDialog}
        onClose={() => {
          setShowFirmwareLogDialog(undefined);
        }}
        id={id}
        firmwareLogs={viperAsset?.firmwareUpdates}
        editId={showFirmwareLogDialog}
      />
      <CreateFirmwareLogDialog
        open={showCreateFirmwareLogDialog}
        onClose={() => {
          setShowCreateFirmwareLogDialog(false);
        }}
        id={id}
        firmwareLogs={viperAsset?.firmwareUpdates}
      />
    </>
  );
};
