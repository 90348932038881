import { ApolloError } from '@apollo/client';
import { useMemo } from 'react';

/**
 * Checks if there are any errors other than unauthorized errors.
 *
 * This is useful because GraphQL will return an unauthorized error for any field the
 * user isn't allowed to see, but will also return all the rest of the data for the
 * fields they can see. In this case we're happy to show the user the data that has
 * come back, and we don't want to show any error messages.
 */
export const useIgnoreUnauthorizedErrors = (error: ApolloError | undefined): boolean => {
  return useMemo(() => {
    if (!error) {
      return false;
    }
    if (
      error.clientErrors.length > 0 ||
      error.networkError ||
      error.protocolErrors.length > 0
    ) {
      return true;
    }
    if (
      error.graphQLErrors?.filter(
        (error) => error.extensions['classification'] !== 'UNAUTHORIZED'
      ).length > 0
    ) {
      return true;
    }
    return false;
  }, [error]);
};
