import { Path, PathValue, buildPath } from '~/routes/paths';
import { VLifeReportFormState } from '~/graphql/generated/asset/graphql';

export const formDefaults: Record<
  'review' | 'approve' | 'publish' | string,
  { path: string; to: PathValue; label: string; state: VLifeReportFormState }
> = {
  review: {
    path: Path.V_LIFE_REPORT_REVIEW_LIST_PATH,
    to: Path.V_LIFE_REPORT_REVIEW_PATH,
    label: 'review',
    state: VLifeReportFormState.Created,
  },
  approve: {
    path: Path.V_LIFE_REPORT_APPROVE_LIST_PATH,
    to: buildPath(Path.V_LIFE_REPORT_APPROVE_PATH) as PathValue,
    label: 'approve',
    state: VLifeReportFormState.Reviewed,
  },
  publish: {
    path: Path.V_LIFE_REPORT_PUBLISH_LIST_PATH,
    to: buildPath(Path.V_LIFE_REPORT_PUBLISH_PATH) as PathValue,
    label: 'publish',
    state: VLifeReportFormState.Approved,
  },
};
