import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNegatableFilterOperators } from '~/components/DataGrid/filters/filter-operators';
import { formatInstalledViperAssetPeriods } from '~/features/customer-asset/utils/cell-formatters/formatInstalledViperAssetPeriods';
import { getArrayValue, getDate } from '~/components/DataGrid/value-getters';
import { EnrichedCustomerAssetRow } from '~/features/customer-asset/pages/list/types';
import { CustomerAssetsRowExpander } from '~/features/customer-asset/components/CustomerAssetsRowExpander';
import { StatusPill } from '~/components/DataGrid/components/StatusPill';

import {
  formatBoolean,
  formatVLifeDateRange,
  formatVLifeTimelinePeriods,
  withSuffix,
} from '~/components/DataGrid/value-formatters';
import { useVLifeLicencesFilterOperators } from '~/components/DataGrid/filters/custom/v-life-licences/filter-operators';
import { formatPercentageIRChange } from '~/features/customer-asset/utils/cell-formatters/formatPercentageIRChange';
import { SetUpVLifePill } from '~/components/DataGrid/components/SetUpVLifePill';
import { VLifeStatus } from '~/graphql/generated/asset/graphql';
import { RBACPermissions } from '~/authorization/permissions';
import { useGuardColumns } from '~/authorization/utils/useGuardColumns';
import { useRBAC } from '~/authorization/utils/useRBAC';

export function useCustomerAssetColumns() {
  const { t } = useTranslation('customer-assets');

  const {
    negatableNumberFilterOperators,
    negatableStringFilterOperators,
    negatableDateFilterOperators,
    negatableBooleanFilterOperators,
  } = useNegatableFilterOperators();

  const { vLifeLicenceFilterOperators, vLifeStatusFilterOperators } =
    useVLifeLicencesFilterOperators();

  const { guardColumns } = useGuardColumns();

  const { userHasPermission } = useRBAC();
  const canEditVLifeLicence = userHasPermission([RBACPermissions.V_LIFE_LICENCE_CREATE_PAGE]);

  const columns = useMemo(
    () =>
      guardColumns([
        {
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          renderCell: (params: GridRenderCellParams<EnrichedCustomerAssetRow>) => (
            <CustomerAssetsRowExpander {...params} />
          ),
          filterable: false,
          sortable: false,
        },
        {
          field: 'id',
          headerName: t('id'),
          minWidth: 50,
          maxWidth: 100,
          flex: 1,
          filterOperators: negatableNumberFilterOperators,
        },
        {
          field: 'name',
          headerName: t('channel'),
          filterOperators: negatableStringFilterOperators,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.FIELD_READ],
          field: 'field.name',
          headerName: t('field'),
          filterOperators: negatableStringFilterOperators,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.FIELD_READ],
          field: 'field.country.name',
          headerName: t('country-name'),
          filterOperators: negatableStringFilterOperators,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.FIELD_READ],
          field: 'field.country.continent.name',
          headerName: t('continent'),
          filterOperators: negatableStringFilterOperators,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.COMPANY_READ],
          field: 'customer.name',
          headerName: t('customer'),
          filterOperators: negatableStringFilterOperators,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.COMPANY_READ, RBACPermissions.FIELD_READ],
          field: 'field.defaultCustomer.name',
          headerName: t('field-default-customer'),
          filterOperators: negatableStringFilterOperators,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.COMPANY_READ, RBACPermissions.FIELD_READ],
          field: 'field.defaultOperator.name',
          headerName: t('field-default-operator'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          field: 'viperProjectNumber',
          headerName: t('viper-project-number'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.V_LIFE_LICENCE_READ],
          field: 'vLifeTimeline.currentStatus',
          headerName: t('v-life-licence-type'),
          renderCell: ({
            value,
            row,
          }: GridCellParams<EnrichedCustomerAssetRow, VLifeStatus>) => {
            if (value === undefined) {
              return <></>;
            }
            if (value === VLifeStatus.NotSetUp && canEditVLifeLicence) {
              return <SetUpVLifePill id={row.id} />;
            }
            return <StatusPill status={value} />;
          },
          minWidth: 200,
          flex: 1,
          filterOperators: vLifeStatusFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.V_LIFE_LICENCE_READ],
          field: 'upcomingVLifeConfig',
          headerName: t('upcoming-v-life-config'),
          filterable: false,
          valueFormatter: formatVLifeDateRange,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.V_LIFE_LICENCE_READ],
          field: 'mostRecentVLifeConfig',
          headerName: t('most-recent-v-life-config'),
          filterable: false,
          valueFormatter: formatVLifeDateRange,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.V_LIFE_LICENCE_READ],
          field: 'vLifeTimeline.timeline',
          headerName: t('v-life-licences'),
          filterOperators: vLifeLicenceFilterOperators,
          valueFormatter: formatVLifeTimelinePeriods,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.V_LIFE_LICENCE_READ],
          field: 'mostRecentVLifeConfig.expiryDateCommToCustomer',
          headerName: t('v-life-expiry-date'),
          type: 'date',
          valueGetter: getDate,
          filterOperators: negatableDateFilterOperators,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'platform.name',
          headerName: t('platform-name'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'platform.waterDepth',
          headerName: t('water-depth'),
          filterOperators: negatableNumberFilterOperators,
          minWidth: 200,
          flex: 1,
          valueFormatter: withSuffix('m'),
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'productionControlSystem.pcsManufacturer.name',
          headerName: t('pcs-manufacturer'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'productionControlSystem.pcsApproxInstallationDate',
          headerName: t('pcs-approx-installation-date'),
          minWidth: 200,
          flex: 1,
          type: 'date',
          valueGetter: getDate,
          filterOperators: negatableDateFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'productionControlSystem.epuManufacturer.name',
          headerName: t('epu-manufacturer'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'productionControlSystem.epuApproxInstallationDate',
          headerName: t('epu-approx-installation-date'),
          minWidth: 200,
          flex: 1,
          type: 'date',
          valueGetter: getDate,
          filterOperators: negatableDateFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'productionControlSystem.frequency',
          headerName: t('asset-frequency'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableNumberFilterOperators,
          valueFormatter: withSuffix('Hz'),
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'productionControlSystem.maximumOperatingVoltage',
          headerName: t('epu-max-voltage'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableNumberFilterOperators,
          valueFormatter: withSuffix('V'),
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'productionControlSystem.electricalDistributionType.name',
          headerName: t('asset-electrical-distribution-type'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'productionControlSystem.electricalTopology.name',
          headerName: t('electrical-topology'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'umbilical.manufacturer.name',
          headerName: t('umbilical-manufacturer'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'umbilical.materialType.name',
          headerName: t('umbilical-material-type'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'umbilical.approxInstallationDate',
          headerName: t('approx-umbilical-installation-date'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableNumberFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.PLATFORM_READ],
          field: 'umbilical.length',
          headerName: t('umbilical-length'),
          minWidth: 200,
          flex: 1,
          // type: 'number',
          filterOperators: negatableNumberFilterOperators,
          valueFormatter: withSuffix('km'),
        },
        {
          field: 'operatingVoltage',
          headerName: t('operating-voltage'),
          minWidth: 200,
          flex: 1,
          // type: 'number',
          filterOperators: negatableNumberFilterOperators,
          valueFormatter: withSuffix('V'),
        },
        {
          field: 'referenceIR',
          headerName: t('reference-ir'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableNumberFilterOperators,
          valueFormatter: withSuffix('Ω'),
        },
        {
          field: 'lifetimeStartDate',
          headerName: t('lifetime-start-date'),
          type: 'date',
          valueGetter: getDate,
          filterOperators: negatableDateFilterOperators,
          minWidth: 200,
          flex: 1,
        },
        {
          field: 'couplingType.name',
          headerName: t('coupling-type'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          field: 'alarm1Value',
          headerName: t('alarm-1-value'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableNumberFilterOperators,
          valueFormatter: withSuffix('Ω'),
        },
        {
          field: 'alarm2Value',
          headerName: t('alarm-2-value'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableNumberFilterOperators,
          valueFormatter: withSuffix('Ω'),
        },
        {
          field: 'tripAlarmRelay.name',
          headerName: t('tripAlarmRelay'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          field: 'relayConfiguration.name',
          headerName: t('relay-configuration'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          field: 'couplerUsed',
          headerName: t('coupler-used'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          field: 'interfaceCard.name',
          headerName: t('interface-card'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          field: 'poweringDevice.name',
          headerName: t('powering-device'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          field: 'negativeImpactOnRebootNotes',
          headerName: t('negative-impact-on-reboot-notes'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          field: 'latestFileReceived',
          headerName: t('latest-file-received'),
          minWidth: 200,
          flex: 1,
          type: 'date',
          valueGetter: getDate,
          filterOperators: negatableDateFilterOperators,
        },
        {
          field: 'dataDue',
          headerName: t('data-due'),
          minWidth: 200,
          flex: 1,
          type: 'date',
          valueGetter: getDate,
          filterOperators: negatableDateFilterOperators,
        },
        {
          field: 'notes',
          headerName: t('notes'),
          minWidth: 200,
          flex: 1,
          filterOperators: negatableStringFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.V_LIFE_LICENCE_READ],
          field: 'upcomingVLifeConfig.startDate',
          headerName: t('upcoming-v-life-start-date'),
          type: 'date',
          valueGetter: getDate,
          filterOperators: negatableDateFilterOperators,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.V_LIFE_LICENCE_READ],
          field: 'mostRecentVLifeConfig.startDate',
          headerName: t('recent-v-life-start-date'),
          type: 'date',
          valueGetter: getDate,
          filterOperators: negatableDateFilterOperators,
          minWidth: 200,
          flex: 1,
        },
        {
          requiredPermissions: [RBACPermissions.V_LIFE_LICENCE_READ],
          field: 'mostRecentVLifeConfig.isExpired',
          headerName: t('recent-v-life-expired'),
          type: 'boolean',
          width: 50,
          flex: 1,
          valueFormatter: formatBoolean.bind({}, t('yes'), t('no')),
          filterOperators: negatableBooleanFilterOperators,
        },
        {
          requiredPermissions: [RBACPermissions.V_LIFE_LICENCE_READ],
          field: 'mostRecentVLifeConfig.isDisabled',
          headerName: t('recent-v-life-disabled'),
          type: 'boolean',
          width: 50,
          flex: 1,
          valueFormatter: formatBoolean.bind({}, t('csv::yes'), t('csv::no')),
        },
        {
          requiredPermissions: [RBACPermissions.V_LIFE_LICENCE_READ],
          field: 'mostRecentVLifeConfig.deactivationReason.name',
          headerName: t('recent-v-life-deactivation-reason'),
          filterOperators: negatableStringFilterOperators,
          minWidth: 200,
          flex: 1,
        },
        {
          field: 'installedViperAssetPeriods',
          headerName: 'Installed Viper Asset Periods',
          filterOperators: negatableNumberFilterOperators,
          filterable: false,
          sortable: false,
          valueGetter: getArrayValue,
          valueFormatter: formatInstalledViperAssetPeriods,
          minWidth: 200,
          flex: 1,
        },
        {
          field: 'latestIRValue',
          headerName: t('latest-ir-reading'),
          filterOperators: negatableNumberFilterOperators,
          minWidth: 200,
          flex: 1,
          valueFormatter: withSuffix('Ω'),
        },
        {
          field: 'percentageIRChange',
          headerName: t('ir-percentage-change'),
          filterOperators: negatableNumberFilterOperators,
          minWidth: 200,
          flex: 1,
          valueFormatter: formatPercentageIRChange,
        },
        {
          field: 'mostRecentVLifeConfig.flaggedForDecommission',
          headerName: t('flagged-for-decommission'),
          filterOperators: negatableBooleanFilterOperators,
          valueFormatter: formatBoolean.bind({}, t('yes'), t('no')),
          minWidth: 200,
          flex: 1,
        },
        {
          field: 'mostRecentVLifeConfig.flaggedForDecommissionDate',
          headerName: t('flagged-for-decommission-date'),
          filterOperators: negatableDateFilterOperators,
          minWidth: 200,
          flex: 1,
          type: 'date',
          valueGetter: getDate,
        },
      ]),
    [
      guardColumns,
      t,
      negatableNumberFilterOperators,
      negatableStringFilterOperators,
      vLifeStatusFilterOperators,
      vLifeLicenceFilterOperators,
      negatableDateFilterOperators,
      negatableBooleanFilterOperators,
      canEditVLifeLicence,
    ]
  );
  return { columns };
}
