import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Actions } from '~/components/Actions/Actions';
import { Toast } from '~/components/Toast/Toast';
import { Path } from '~/routes/paths';
import { useViperAssets } from '../../hooks/useViperAssets';
import { DataGrid } from '~/components/DataGrid';
import { LoadingSkeleton } from '~/components/LoadingSkeleton/LoadingSkeleton';
import { useEnrichViperAssets } from '../../hooks/useEnrichViperAssets';
import { IfPermitted } from '~/authorization/utils/IfPermitted';
import { RBACPermissions } from '~/authorization/permissions';

export const ListViperAssets: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('viper-assets');

  const [closeError, setCloseError] = useState(false);

  const { rows, columns, loading, error } = useViperAssets();
  const { enrichedRows } = useEnrichViperAssets(
    rows as unknown[] as { serialNumber: string }[]
  );

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <IfPermitted
        requiredPermissions={[
          RBACPermissions.VIPER_ASSET_WRITE,
          RBACPermissions.VIPER_ASSET_COMMERCIAL_WRITE,
        ]}
        permissionEvaluationMethod={'userHasAllPermissions'}
      >
        <Actions
          actions={[
            {
              label: t('create-asset'),
              onClick: () => {
                navigate(Path.VIPER_ASSET_CREATE_PATH);
              },
            },
          ]}
        />
      </IfPermitted>
      <Toast
        severity='error'
        open={Boolean(error) && !closeError}
        onClose={() => setCloseError(true)}
      >
        {t('unable-to-load')}
      </Toast>
      <DataGrid
        statePersistencyId='viper-assets'
        initialColumnVisibilityModel={{
          id: false,
          serialNumber: true,
          'part.name': true,
          'manufacturer.name': false,
          dateOfManufacture: false,
          'bomRevision.name': false,
          'primaryComms.name': false,
          'currentFirmwareRevision.name': true,
          ipAddress: false,
          'ipAssignmentMethod.name': false,
          macAddress: false,
          adminPassword: false,
          'rootRevisionPassword.name': false,
          maxIrCap: false,
          remoteAccess: false,
          pollingIpAddress: false,
          dateOfPurchase: false,
          'endClient.name': false,
          'customer.name': false,
          generalNotes: false,
          'latestLifecycleEvent.eventType': true,
          'latestInstallationEvent.dateOfLifecycleEvent': true,
        }}
        rows={enrichedRows ?? []}
        columns={columns}
        rowClickParamName='id'
        rowClickUrlBase={Path.VIPER_ASSET_OVERVIEW_PATH}
      />
    </>
  );
};
