import { Field, Schema } from '@data-driven-forms/react-form-renderer';
import { useCreateWizardFieldRBACGuardFilter } from '~/components/Form/Wizard/useCreateWizardFieldRBACGuardFilter';

export const useGuardCreateWizardSchema = (schema: Schema) => {
  const { fieldRBACGuardFilter } = useCreateWizardFieldRBACGuardFilter();

  const wizardSchema = schema.fields[0];
  const guardedSections = wizardSchema.fields.filter(fieldRBACGuardFilter);
  const sectionsWithGuardedFields = guardedSections.map((section: Field) => {
    const guardedFields = section.fields.filter(fieldRBACGuardFilter);
    return {
      ...section,
      fields: guardedFields,
    };
  });

  const guardedSchema: Schema = {
    ...schema,
    fields: [
      {
        ...wizardSchema,
        fields: sectionsWithGuardedFields,
      },
    ],
  };

  return { guardedSchema };
};
