import React from 'react';
import { CustomerAssetGraphSection } from '~/features/customer-asset/pages/tabs/graphs/CustomerAssetGraphSection';
import { UploadVLogsButton } from '~/features/v-logs/components/UploadVLogsButton';
import { IfPermitted } from '~/authorization/utils/IfPermitted';
import { RBACPermissions } from '~/authorization/permissions';

export const CustomerAssetGraph: React.FC<{ channelId?: string }> = ({ channelId }) => {
  return (
    <>
      <IfPermitted requiredPermissions={[RBACPermissions.V_LOG_UPLOAD]}>
        <UploadVLogsButton isRenderedAtPageLevel={false} />
      </IfPermitted>
      <CustomerAssetGraphSection channelId={channelId} />
    </>
  );
};
