import { useRBAC } from '~/authorization/utils/useRBAC';
import { Field } from '@data-driven-forms/react-form-renderer';
import { GuardedField } from '~/components/Form/types';

export const useCreateWizardFieldRBACGuardFilter = () => {
  const { userHasPermission } = useRBAC();

  const fieldRBACGuardFilter = (field: Field | GuardedField) => {
    if (field.readPermission && !userHasPermission([field.readPermission])) {
      return false;
    }

    if (field.writePermission && !userHasPermission([field.writePermission])) {
      return false;
    }

    return true;
  };

  return {
    fieldRBACGuardFilter,
  };
};
