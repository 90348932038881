import { EditPane } from '~/components/Form/EditPane/EditPane';
import { ViewPane } from '~/components/Form/ViewPane/ViewPane';
import { DataList } from '~/components/DataList/DataList';
import React from 'react';
import { Schema } from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';

import { CustomButtonProps } from '~/components/Form/CustomButtonProps';
import { useRBAC } from '~/authorization/utils/useRBAC';
import { RBACPermission } from '~/authorization/permissions';
import { EditPaneContainer } from '~/components/Form/EditPane/EditPaneContainer';

interface EditOrViewPaneProps {
  editable?: boolean;
  editing: string | undefined;
  section: Schema & {
    name: string;
    component: 'group' | 'panel';
    readPermission?: RBACPermission;
    writePermission?: RBACPermission;
  };
  saving: boolean | undefined;
  validate:
    | ((values: Record<string, unknown>) => Record<string, string> | undefined)
    | undefined;
  onSave:
    | ((section: Schema & { name: string }, values: Record<string, unknown>) => void)
    | undefined;
  onCancel: (() => void) | undefined;
  setEditing: ((section: string) => void) | undefined;
  t: TFunction;
  saveButtonLabel: string | undefined;
  customButton?: CustomButtonProps;
}

export const EditOrViewPane = ({
  editing,
  section,
  saving,
  validate,
  onSave,
  onCancel,
  setEditing,
  t,
  saveButtonLabel,
  customButton,
}: EditOrViewPaneProps) => {
  const { userHasPermission } = useRBAC();

  const userCanWritePane =
    !section.writePermission || userHasPermission([section.writePermission]);

  const userCanReadPane =
    !section.readPermission || userHasPermission([section.readPermission]);

  if (userCanWritePane && editing === section.name) {
    return (
      <EditPane
        data-testid={section.name}
        key={section.name}
        disableFields={saving}
        fields={section.fields}
        validate={validate}
        onSubmit={(values) => {
          onSave?.(section, values);
        }}
        onCancel={onCancel}
        container={({ children }) => (
          // The submit button needs to be able to access the form api, so it needs
          // to be rendered inside the form. But we're outside the form here. So we
          // pass the container into the EditPane, so that it can get rendered as a
          // wrapper around the form template, but inside the form renderer.
          <EditPaneContainer
            editable={userCanWritePane}
            title={typeof section.title === 'string' ? t(section.title) : section.title}
            saving={!!saving}
            saveButtonLabel={saveButtonLabel}
          >
            {children}
          </EditPaneContainer>
        )}
      />
    );
  }
  if (userCanReadPane) {
    return (
      <ViewPane
        editable={userCanWritePane}
        data-testid={section.name}
        key={section.name}
        editingThisSection={false}
        editingAnotherSection={!!editing}
        onEditClicked={() => setEditing?.(section.name)}
        title={typeof section.title === 'string' ? t(section.title) : section.title}
        saving={!!saving}
        saveButtonLabel={saveButtonLabel}
        customButton={customButton}
      >
        <DataList fields={section.fields} />
      </ViewPane>
    );
  }
  return <></>;
};
