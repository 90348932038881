import { componentTypes, validatorTypes } from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { SelectActionHandler } from '~/components/Form/formFields/select-action-handler';
import {
  GetChannelQuery,
  GetCreateChannelOptionsQuery,
} from '~/graphql/generated/asset/graphql';

interface GetIdentificationEditSchemaProps {
  t: TFunction;
  fields?: GetCreateChannelOptionsQuery['fields'];
  customers?: GetCreateChannelOptionsQuery['customers'];
  channel?: GetChannelQuery['channel'];
  editing?: boolean;
  onAddField: SelectActionHandler;
  onEditField: SelectActionHandler;
}

export function getIdentificationEditSchema({
  t,
  fields,
  customers,
  channel,
  onAddField,
  onEditField,
}: GetIdentificationEditSchemaProps) {
  return {
    fields: [
      {
        name: 'name',
        label: t('customer-asset-forms::channel-name'),
        component: componentTypes.TEXT_FIELD,
        isRequired: true,
        initialValue: channel?.name,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        component: componentTypes.SELECT,
        name: 'fieldId',
        sortAlphabetical: true,
        isSearchable: true,
        label: t('customer-asset-forms::field'),
        isRequired: true,
        initialValue: channel?.field,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: fields,
        actionLinks: [
          {
            label: t('viper::actions/add'),
            action: onAddField,
          },
          {
            label: t('viper::actions/edit'),
            action: onEditField,
            disabled: (value: string) => !value,
          },
        ],
      },
      {
        name: 'country',
        label: t('customer-asset-forms::country'),
        component: componentTypes.PLAIN_TEXT,
        initialValue: channel?.field?.country.name,
        condition: { when: 'fieldId', isNotEmpty: true },
        resolveProps: (
          _props: Record<string, unknown>,
          _field: Record<string, unknown>,
          formOptions: { getState: () => { values: { fieldId: string } } }
        ) => {
          const selectedFieldId = formOptions.getState().values.fieldId;
          const selectedField = fields?.find((field) => field.id === selectedFieldId);
          return {
            initialValue: selectedField?.country?.name,
          };
        },
      },
      {
        name: 'continent',
        label: t('customer-asset-forms::continent'),
        component: componentTypes.PLAIN_TEXT,
        initialValue: channel?.field?.country.continent.name,
        condition: { when: 'fieldId', isNotEmpty: true },
        resolveProps: (
          _props: Record<string, unknown>,
          _field: Record<string, unknown>,
          formOptions: { getState: () => { values: { fieldId: string } } }
        ) => {
          const selectedFieldId = formOptions.getState().values.fieldId;
          const selectedField = fields?.find((field) => field.id === selectedFieldId);
          return {
            initialValue: selectedField?.country?.continent.name,
          };
        },
      },
      {
        component: componentTypes.SELECT,
        name: 'customerId',
        label: t('customer-asset-forms::customer'),
        helperText: t('customer-asset-forms::customer-helper'),
        isRequired: true,
        sortAlphabetical: true,
        isSearchable: true,
        initialValue: channel?.customer,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: customers,
      },
      {
        component: componentTypes.SELECT,
        name: 'operatorId',
        label: t('customer-asset-forms::operator'),
        helperText: t('customer-asset-forms::operator-helper'),
        isRequired: true,
        sortAlphabetical: true,
        isSearchable: true,
        initialValue: channel?.operator,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: customers,
      },
      {
        name: 'viperProjectNumber',
        label: t('customer-asset-forms::viper-project-number'),
        initialValue: channel?.viperProjectNumber,
        component: componentTypes.TEXT_FIELD,
      },
    ],
  };
}
