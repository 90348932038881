import { GetViperAssetQuery, LifecycleEventType } from '~/graphql/generated/asset/graphql';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { formatPerson } from '~/utils/format-person';
import { useRBAC } from '~/authorization/utils/useRBAC';
import { RBACPermission, RBACPermissions } from '~/authorization/permissions';
import { usePastLifecycleEvents } from '~/features/viper-asset/hooks/lifecycle-history-table/usePastLifecycleEvents';

export const useViperAssetLifecycleDetailsColumns = (
  viperAsset: GetViperAssetQuery['viperAsset']
) => {
  const { t } = useTranslation('viper-asset-forms');
  const { userHasPermission } = useRBAC();
  const { pastLifecycleEvents } = usePastLifecycleEvents(viperAsset);

  const guardColumn = useCallback(
    (columnName: string, value: string, requiredPermission: RBACPermission) => {
      if (userHasPermission([requiredPermission])) {
        return {
          [columnName]: value,
        };
      }
      return {};
    },
    [userHasPermission]
  );

  const lifeCycleHistoryDetails: Record<string, unknown>[] | undefined = useMemo(
    () =>
      pastLifecycleEvents?.map((lifecycleEvent) => {
        // Common details, e.g ID and User
        const eventDetails = {
          _id: lifecycleEvent?.id,
          // t('lifecycle/installation/person')
          // t('lifecycle/uninstallation/person')
          // t('lifecycle/commissioning/person')
          // t('lifecycle/decommissioning/person')
          ...guardColumn(
            t(`lifecycle/${lifecycleEvent.eventType.toLowerCase()}/person`),
            formatPerson(lifecycleEvent?.person) ?? '',
            RBACPermissions.CONTACT_READ
          ),
        };

        // Channel related details, e.g
        const channelDetailsColumns = {
          ...guardColumn(
            t('lifecycle/channel-name'),
            lifecycleEvent?.channel.name ?? '',
            RBACPermissions.CUSTOMER_ASSET_READ
          ),
          ...guardColumn(
            t('lifecycle/field-name'),
            lifecycleEvent?.channel.field?.name ?? '',
            RBACPermissions.FIELD_READ
          ),
          ...guardColumn(
            t('lifecycle/customer-name'),
            lifecycleEvent?.channel.customer?.name ?? '',
            RBACPermissions.COMPANY_READ
          ),
        };

        switch (lifecycleEvent.eventType) {
          case LifecycleEventType.Installation:
            return {
              ...eventDetails,
              [t('lifecycle/installation/type')]: lifecycleEvent?.installationType?.name,
              [t('lifecycle/installation/notes')]: lifecycleEvent?.notes,
              ...channelDetailsColumns,
            };
          case LifecycleEventType.Commissioning:
            return {
              ...eventDetails,
              [t('lifecycle/commissioning/notes')]: lifecycleEvent?.notes,
              ...channelDetailsColumns,
            };
          case LifecycleEventType.Decommissioning:
            return {
              ...eventDetails,
              [t('lifecycle/decommissioning/reason')]:
                lifecycleEvent?.decommissionReason?.name,
              [t('lifecycle/decommissioning/notes')]: lifecycleEvent?.notes,
              ...channelDetailsColumns,
            };
          case LifecycleEventType.Uninstallation:
            return {
              ...eventDetails,
              [t('lifecycle/uninstallation/status')]: lifecycleEvent?.uninstallStatus?.name,
              [t('lifecycle/uninstallation/notes')]: lifecycleEvent?.notes,
              ...channelDetailsColumns,
            };
          default:
            return eventDetails;
        }
      }),
    [pastLifecycleEvents, guardColumn, t]
  );
  return { lifecycleHistoryDetailColumns: lifeCycleHistoryDetails };
};
