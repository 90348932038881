import { Kind, NamedTypeNode, TypeNode } from 'graphql/index';

export const unwrapType = (type: TypeNode): NamedTypeNode => {
  switch (type.kind) {
    case Kind.LIST_TYPE:
      return unwrapType(type.type);
    case Kind.NON_NULL_TYPE:
      return unwrapType(type.type);
    case Kind.NAMED_TYPE:
      return type;
  }
};
