import { useCallback } from 'react';
import { GetVLogFilesDocument } from '~/graphql/generated/measurement/graphql';
import { useUpload } from '~/components/Upload/useUpload';

import { env } from '~/config/environmentVariables';
import { measurementApolloClient } from '~/common/graphql-measurement';

function reFetchVLogFiles() {
  return measurementApolloClient.refetchQueries({ include: [GetVLogFilesDocument] });
}

export function useUploadVLogs() {
  const { uploadFile } = useUpload();

  const uploadFiles = useCallback(
    async (files: File[]) => {
      if (files) {
        await Promise.all(
          files.map((file) =>
            uploadFile(
              `${env.REACT_APP_MEASUREMENT_API_ENDPOINT}/vlogfile`,
              file,
              reFetchVLogFiles,
              reFetchVLogFiles
            )
          )
        );
      }
    },
    [uploadFile]
  );

  return {
    uploadFiles,
  };
}
