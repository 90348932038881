import React from 'react';
import { Schema } from '@data-driven-forms/react-form-renderer';
import { Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Card } from '~/components/Card/Card';
import { LoadingSkeleton } from '../../LoadingSkeleton/LoadingSkeleton';
import { Table } from '../../Table/Table';
import { transientOptions } from '~/themes/utils';
import { EditOrViewPane } from '~/components/Form/EditOrViewPane/EditOrViewPane';
import { RBACPermission } from '~/authorization/permissions';
import { useRBAC } from '~/authorization/utils/useRBAC';

type GuardedSectionGroupSchema = Schema & {
  name: string;
  component: 'group' | 'panel';
  highlighted?: boolean;
  when?: (data?: Schema | null | undefined) => boolean;
  highlightColor?: string;
  readPermission?: RBACPermission;
  writePermission?: RBACPermission;
};

const SectionGroup = styled(Stack, transientOptions)<{
  $highlighted?: string;
}>`
  ${({ theme, $highlighted }) => `
    background: ${$highlighted ?? theme.palette.primary.contrastText};
    box-shadow: 0 0 0 10px ${$highlighted ?? 'transparent'};
    border-radius: 5px;
  `}
`;

interface DataCardStackProps {
  data: Schema | null | undefined;
  loading: boolean;
  saving?: boolean;
  translationKey?: string;
  setEditing?: (section: string) => void;
  editing?: string;
  validate?: (values: Record<string, unknown>) => Record<string, string> | undefined;
  onSave?: (section: Schema & { name: string }, values: Record<string, unknown>) => void;
  onCancel?: () => void;
  saveButtonLabel?: string;
}

export const DataCardStack = ({
  data,
  translationKey,
  loading,
  saving,
  setEditing,
  editing,
  validate,
  onSave,
  onCancel,
  saveButtonLabel,
}: DataCardStackProps) => {
  const { t } = useTranslation(translationKey);
  const { userHasPermission } = useRBAC();

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (!data) {
    return <p>no data</p>;
  }

  const sections = data.fields[0].fields;
  return (
    <Stack spacing={3}>
      {sections
        .filter((section: GuardedSectionGroupSchema) => {
          return !section.readPermission || userHasPermission([section.readPermission]);
        })
        .map((section: GuardedSectionGroupSchema) => {
          if (section.when && !section.when()) {
            return;
          }
          if (section.component === 'group') {
            return (
              <SectionGroup
                key={section.name}
                aria-label={section.name}
                $highlighted={section.highlightColor}
                spacing={2}
                data-testid='panel-group'
              >
                {section.fields.map((pane) => {
                  if (pane.component === 'panel') {
                    if (pane.when && !pane.when()) {
                      return;
                    }
                    return (
                      <EditOrViewPane
                        key={pane.name}
                        editing={editing}
                        section={
                          pane as Schema & {
                            name: string;
                            title?: React.ReactNode;
                            component: 'group' | 'panel';
                            readPermission?: RBACPermission;
                            writePermission?: RBACPermission;
                          }
                        }
                        saving={saving}
                        validate={validate}
                        onSave={onSave}
                        onCancel={onCancel}
                        setEditing={setEditing}
                        t={t}
                        saveButtonLabel={saveButtonLabel}
                        customButton={pane.customButton}
                      />
                    );
                  }
                  if (pane.component === 'list') {
                    if (pane.when && !pane.when()) {
                      return;
                    }
                    let content;
                    if (pane.data.length === 0) {
                      if (!pane.emptyText) {
                        return;
                      }
                      content = <Typography sx={{ ml: 0.75 }}>{pane.emptyText}</Typography>;
                    } else {
                      content = (
                        <Table
                          key={pane.name}
                          data={pane.data}
                          nestedData={pane.nestedData}
                          translationKey={translationKey}
                          omittedKeys={pane.omittedKeys}
                        />
                      );
                    }
                    if (pane.showTitle) {
                      return (
                        <Card key={pane.name}>
                          <Typography variant='h3' component='h3' sx={{ ml: 0.75, mb: 1.25 }}>
                            {pane.title}
                          </Typography>
                          {content}
                        </Card>
                      );
                    } else {
                      return content;
                    }
                  }
                  if (pane.component === 'custom') {
                    if (pane.when && !pane.when()) {
                      return;
                    }
                    return (
                      <React.Fragment key={pane.name}>{pane.customComponent}</React.Fragment>
                    );
                  }
                })}
              </SectionGroup>
            );
          }

          return (
            <EditOrViewPane
              key={section.name}
              editing={editing}
              section={section}
              saving={saving}
              validate={validate}
              onSave={onSave}
              onCancel={onCancel}
              setEditing={setEditing}
              t={t}
              saveButtonLabel={saveButtonLabel}
            />
          );
        })}
    </Stack>
  );
};
