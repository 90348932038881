import React, { useCallback, useState } from 'react';
import { useViperAsset } from '../../hooks/useViperAsset';
import { LoadingSkeleton } from '~/components/LoadingSkeleton/LoadingSkeleton';

import { TabPanel } from '~/components/TabPanel/TabPanel';
import { Toast } from '~/components/Toast/Toast';
import { Tabs, Tab } from '@mui/material';
import { OverviewTab } from '~/features/viper-asset/pages/tabs/OverviewTab';
import { useTranslation } from 'react-i18next';
import { FormOptionsProvider } from '~/features/viper-asset/contexts/FormOptionsContext';
import { buildPath, Path } from '~/routes/paths';
import { useLocation, useNavigate } from 'react-router-dom';
import { LogsTab } from '~/features/viper-asset/pages/tabs/LogsTab';

// t('error-saving/INSTALLATION');
// t('error-saving/COMMISSIONING');
// t('error-saving/DECOMMISSIONING');
// t('error-saving/UNINSTALLATION');
// t('viper-asset::actions')

export const ViewViperAsset = () => {
  const { t } = useTranslation('viper-asset');
  const navigate = useNavigate();

  const { id, loading, viperAsset } = useViperAsset();

  const [closeError, setCloseError] = useState(false);

  const location = useLocation();
  const handleTabChange = useCallback(
    (_e: React.SyntheticEvent, newValue: string) => navigate(newValue),
    [navigate]
  );

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (!viperAsset) {
    return <p>{t('no-data')}</p>;
  }

  return (
    <FormOptionsProvider>
      <Toast
        severity='error'
        open={Boolean(loading) && !closeError}
        onClose={() => setCloseError(true)}
      >
        {t('unable-to-load')}
      </Toast>
      <Tabs
        value={location.pathname}
        onChange={handleTabChange}
        aria-label={`tab-${location.pathname}`}
        sx={{ mb: 4 }}
      >
        <Tab label={t('overview')} value={buildPath(Path.VIPER_ASSET_OVERVIEW_PATH, { id })} />
        <Tab label={t('logs')} value={buildPath(Path.VIPER_ASSET_LOGS_PATH, { id })} />
      </Tabs>
      <TabPanel
        index={buildPath(Path.VIPER_ASSET_OVERVIEW_PATH, { id })}
        value={location.pathname}
      >
        <OverviewTab viperAsset={viperAsset} />
      </TabPanel>
      <TabPanel
        index={buildPath(Path.VIPER_ASSET_LOGS_PATH, { id })}
        value={location.pathname}
      >
        <LogsTab />
      </TabPanel>
    </FormOptionsProvider>
  );
};
