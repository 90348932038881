import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DataGridCustomExpander } from '~/components/DataGrid/components/DataGridCustomExpander';
import { VLogsRowData } from '../pages/ListVLogsPage';

export const VLogRowExpander = (props: GridRenderCellParams<VLogsRowData>) => {
  const { row } = props;
  const vLogRow = row['events'];

  if (!vLogRow || !vLogRow.length) {
    return <></>;
  }

  return <DataGridCustomExpander {...props} />;
};
