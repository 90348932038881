import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetChannel,
  useGetCreateChannelOptions,
  useGetInstalledViperAssets,
} from '~/features/customer-asset/queries/customer-asset';
import { getCustomerAssetEditSchema } from '~/features/customer-asset/form-schemas/edit/customer-asset/customerAssetEditSchema';
import { formatDate } from '~/utils/date-utils';
import { useGetCreateViperServiceOptions } from '~/features/customer-asset/queries/viper-service';
import { LifecycleEventType, Platform } from '~/graphql/generated/asset/graphql';
import { SelectActionHandler } from '~/components/Form/formFields/select-action-handler';
import { buildPath, Path } from '~/routes/paths';
import { Link } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Status } from '~/components/Status/Status';
import { useStatusProps } from '~/features/customer-asset/hooks/useStatusProps';
import { LifecyclePill } from '~/components/LifecyclePill/LifecyclePill';
import { StatusPill } from '~/components/DataGrid/components/StatusPill';
import { formatDateRange } from '~/components/DataGrid/utils';

export const useCustomerAssetSchema = (
  id: string,
  createLicenceComponent: React.ReactNode,
  editing?: string
) => {
  const { t } = useTranslation('customer-asset');
  const navigate = useNavigate();

  const { data, loading: channelLoading } = useGetChannel(id);
  const { data: allViperAssets, loading: allViperAssetsLoading } =
    useGetInstalledViperAssets(id);

  const { data: channelOptions, loading: channelOptionsLoading } =
    useGetCreateChannelOptions();
  const { data: licenceOptions, loading: licenceOptionsLoading } =
    useGetCreateViperServiceOptions();

  const [createDialogOpen, setCreateDialogOpen] = useState<string | undefined>(undefined);
  const [updateDialogOpen, setUpdateDialogOpen] = useState<
    { id?: string; type: string } | undefined
  >(undefined);
  const [platform, setPlatform] = useState<Platform | undefined>(undefined);
  const [deleteId, setDeleteId] = useState<string>();

  const onAddField = useCallback(() => setCreateDialogOpen('field'), []);
  const onAddPlatform = useCallback(() => setCreateDialogOpen('platform'), []);
  const onAddProductionControlSystem = useCallback<SelectActionHandler>(
    (_event, value, values) => {
      setPlatform(
        channelOptions?.platforms?.find((platform) => platform.id === values['platform'])
      );
      setCreateDialogOpen('pcs');
    },
    [channelOptions?.platforms]
  );
  const onAddUmbilical = useCallback<SelectActionHandler>(
    (_event, value, values) => {
      setPlatform(
        channelOptions?.platforms?.find((platform) => platform.id === values['platform'])
      );
      setCreateDialogOpen('umbilical');
    },
    [channelOptions?.platforms]
  );

  const onEditField = useCallback((event: React.MouseEvent, value: string | undefined) => {
    setUpdateDialogOpen({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: value!,
      type: 'field',
    });
  }, []);

  const onEditPlatform = useCallback((event: React.MouseEvent, value: string | undefined) => {
    setUpdateDialogOpen({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: value!,
      type: 'platform',
    });
  }, []);

  const onEditUmbilical = useCallback((event: React.MouseEvent, value: string | undefined) => {
    setUpdateDialogOpen({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: value!,
      type: 'umbilical',
    });
  }, []);

  const onEditProductionControlSystem = useCallback(
    (event: React.MouseEvent, value: string | undefined) => {
      setUpdateDialogOpen({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: value!,
        type: 'pcs',
      });
    },
    []
  );

  const onDeleteUpcomingLicence = useCallback(async (id: string) => {
    setDeleteId(id);
  }, []);

  const navigateToCreateLicence = useCallback(() => {
    navigate(buildPath(Path.V_LIFE_LICENCE_CREATE_PATH, { id }));
  }, [id, navigate]);

  const openDisableDialog = useCallback(() => {
    setUpdateDialogOpen({
      id: data?.channel?.mostRecentVLifeConfig?.id,
      type: 'disable-vlife',
    });
  }, [data?.channel?.mostRecentVLifeConfig?.id]);

  const statusProps = useStatusProps({
    data,
    editing,
    onActivatedClick: openDisableDialog,
    onDeactivatedClick: navigateToCreateLicence,
  });

  const vLifeStatusComponent = useMemo(() => <Status {...statusProps} />, [statusProps]);

  const channelSchema = useMemo(
    () =>
      getCustomerAssetEditSchema({
        t,
        channel: data?.channel,
        editing,
        couplingTypes: channelOptions?.couplingTypes,
        customers: channelOptions?.customers,
        electricalDistributionTypes: channelOptions?.electricalDistributionTypes,
        electricalTopologies: channelOptions?.electricalTopologies,
        fields: channelOptions?.fields,
        interfaceCards: channelOptions?.interfaceCards,
        poweringDevices: channelOptions?.poweringDevices,
        relayConfigurations: channelOptions?.relayConfigurations,
        tripAlarmRelays: channelOptions?.tripAlarmRelays,
        vlifeLicenceTypes: licenceOptions?.vlifeLicenceTypes,
        vlifeVersions: licenceOptions?.vlifeVersions,
        platforms: channelOptions?.platforms,
        productionControlSystems: channelOptions?.productionControlSystems,
        umbilicals: channelOptions?.umbilicals,
        deactivationReasons: channelOptions?.deactivationReasons,
        vLifeHistory: data?.channel?.vLifeTimeline?.timeline?.map((timelinePeriod, i) => ({
          _id: i,
          [t('customer-asset-forms::v-life/v-life-id')]: `${
            data?.channel?.id ?? '-1'
          } ${formatDateRange(timelinePeriod?.startDate, timelinePeriod?.endDate)}`,
          [t('customer-asset-forms::v-life/start-date')]: {
            label: formatDate(timelinePeriod?.startDate),
            value: timelinePeriod?.startDate,
          },
          [t('customer-asset-forms::v-life/expiry-date-comm-to-customer')]: {
            label: formatDate(timelinePeriod?.endDate),
            value: timelinePeriod?.endDate,
          },
          [t('customer-asset-forms::v-life/licence-type')]: {
            label: <StatusPill status={timelinePeriod.status} />,
            value: timelinePeriod.status,
          },
        })),
        viperAssetHistory:
          data?.channel?.latestLifecycleEvent &&
          data?.channel?.latestLifecycleEvent.eventType !== LifecycleEventType.Uninstallation
            ? allViperAssets?.channel?.installedViperAssets
                .map((viperAsset) => ({
                  [t('customer-asset-forms::viper-asset-history/part-number')]:
                    viperAsset.part.name,
                  [t('customer-asset-forms::viper-asset-history/serial-number')]: {
                    label: (
                      <Link
                        component={RouterLink}
                        to={buildPath(Path.VIPER_ASSET_OVERVIEW_PATH, {
                          id: viperAsset.id,
                        })}
                      >
                        {viperAsset.serialNumber}
                      </Link>
                    ),
                    value: viperAsset.serialNumber,
                  },
                  [t('customer-asset-forms::viper-asset-history/date-installed')]: {
                    label: formatDate(
                      viperAsset.latestInstallationEvent?.dateOfLifecycleEvent
                    ),
                    value: viperAsset.latestInstallationEvent,
                  },
                  [t('customer-asset-forms::viper-asset-history/current-state')]: {
                    label: (
                      <LifecyclePill eventType={viperAsset.latestLifecycleEvent?.eventType} />
                    ),
                    value: viperAsset.latestLifecycleEvent?.eventType,
                  },
                }))
                .reverse()
            : [],
        vLifeHistoryDetails: data?.channel?.historicalVLifeConfigs.map((vlifeConfig) => ({
          _id: vlifeConfig?.id,
          [t('customer-asset-forms::v-life/version')]: vlifeConfig?.vlifeVersion.name,
          [t('customer-asset-forms::v-life/purchase-order-number')]:
            vlifeConfig?.vlifeLicence.purchaseOrderNumber,
          [t('customer-asset-forms::v-life/expiry-date-in-config')]: formatDate(
            vlifeConfig?.expiryDateInConfig
          ),
          [t('customer-asset-forms::v-life/star-jar-project-number')]:
            vlifeConfig?.vlifeLicence.starjarProjectNumber,
          [t('customer-asset-forms::v-life/sales-order-number')]:
            vlifeConfig?.vlifeLicence.salesOrderNumber,
          [t('customer-asset-forms::v-life/general-notes')]: vlifeConfig?.notes,
        })),
        createLicenceComponent,
        vLifeStatusComponent,
        onAddField,
        onAddPlatform,
        onAddUmbilical,
        onAddProductionControlSystem,
        onEditField,
        onEditPlatform,
        onEditUmbilical,
        onEditProductionControlSystem,
        onDeleteUpcomingLicence,
      }),
    [
      t,
      data?.channel,
      editing,
      channelOptions?.couplingTypes,
      channelOptions?.customers,
      channelOptions?.electricalDistributionTypes,
      channelOptions?.electricalTopologies,
      channelOptions?.fields,
      channelOptions?.interfaceCards,
      channelOptions?.poweringDevices,
      channelOptions?.relayConfigurations,
      channelOptions?.tripAlarmRelays,
      channelOptions?.platforms,
      channelOptions?.productionControlSystems,
      channelOptions?.umbilicals,
      channelOptions?.deactivationReasons,
      licenceOptions?.vlifeLicenceTypes,
      licenceOptions?.vlifeVersions,
      allViperAssets?.channel?.installedViperAssets,
      createLicenceComponent,
      vLifeStatusComponent,
      onAddField,
      onAddPlatform,
      onAddUmbilical,
      onAddProductionControlSystem,
      onEditField,
      onEditPlatform,
      onEditUmbilical,
      onEditProductionControlSystem,
      onDeleteUpcomingLicence,
    ]
  );

  return {
    channel: data,
    channelSchema,
    loading:
      channelLoading ||
      channelOptionsLoading ||
      licenceOptionsLoading ||
      allViperAssetsLoading,
    createDialogOpen,
    updateDialogOpen,
    setCreateDialogOpen,
    setUpdateDialogOpen,
    platform,
    deleteId,
    setDeleteId,
  };
};
