import { useContext } from 'react';
import { RBACContext } from '~/authorization/RBACProvider';
import { RBACPermission } from '~/authorization/permissions';

export const useRBAC = () => {
  const userPermissions = useContext(RBACContext);

  const userHasPermission = (permission: RBACPermission[]) => {
    return userPermissions?.includes(permission[0]);
  };

  const userHasAllPermissions = (permissions: RBACPermission[]): boolean => {
    return permissions.every((permission) => userPermissions?.includes(permission));
  };

  const userHasSomePermissions = (permissions: RBACPermission[]): boolean => {
    return permissions.some((permission) => userPermissions?.includes(permission));
  };

  return {
    userHasPermission,
    userHasAllPermissions,
    userHasSomePermissions,
  };
};
