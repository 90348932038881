import { useVLogs } from '~/features/v-logs/hooks/useVLogs';
import { useVLogColumns } from '~/features/v-logs/hooks/useVLogColumns';
import { VLogExpandableRow } from '~/features/v-logs/components/VLogExpandableRow';
import { DataGrid } from '~/components/DataGrid';
import React, { useState } from 'react';
import { LoadingSkeleton } from '~/components/LoadingSkeleton/LoadingSkeleton';
import { Toast } from '~/components/Toast/Toast';
import { useTranslation } from 'react-i18next';

export const VLogsTable = () => {
  const { t } = useTranslation('v-log');
  const { loading, error, data } = useVLogs();
  const [closeError, setCloseError] = useState(false);
  const { columns } = useVLogColumns();

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Toast
        severity='error'
        open={Boolean(error) && !closeError}
        onClose={() => setCloseError(true)}
      >
        {t('unable-to-load')}
      </Toast>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [
              { field: 'dateUploaded', sort: 'desc' },
              { field: 'status', sort: 'asc' },
            ],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        columns={columns}
        rows={data}
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={({ row }) => <VLogExpandableRow row={row} />}
        loading={loading}
      />
    </>
  );
};
