import React from 'react';
import { EventType, GetVLogFilesQuery } from '~/graphql/generated/measurement/graphql';

import { RowType } from '~/components/DataList/DataList';

import { UploadVLogsButton } from '~/features/v-logs/components/UploadVLogsButton';
import { VLogsTable } from '~/features/v-logs/components/VLogsTable';

export type VLogsRowData = {
  id: string;
  name?: string | null | undefined;
  size?: string | null | undefined;
  person?: string | null | undefined;
  dateUploaded?: string | null | undefined;
  errorMessage?: string | null | undefined;
  status?: EventType | null | undefined;
  [RowType.NODE]?: React.ReactNode;
  events?: GetVLogFilesQuery['vlogFiles'][0]['events'];
};

// t('v-log::name')
// t('v-log::size')
// t('v-log::status')
// t('v-log::dateUploaded')
// t('v-log::uploadPerson')
// t('v-log::errorMessage')
// t('v-log::timestamp')

export const ListVLogsPage = () => {
  return (
    <>
      <UploadVLogsButton isRenderedAtPageLevel={true} />
      <VLogsTable />
    </>
  );
};
