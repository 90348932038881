import {
  componentTypes,
  Schema,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { DateTime } from 'luxon';
import { extraValidatorTypes } from '~/components/Form/utils/validators';
import { formatPerson } from '~/utils/format-person';
import { GetVLifeEditSchemaProps } from '~/features/viper-asset/form-schemas/edit/lifecycle/types';
import { useTranslation } from 'react-i18next';
import { RBACPermissions } from '~/authorization/permissions';

export const useLifecycleUninstallationEditSchema = ({
  people,
  uninstallStatuses,
  lifecycleEvent,
}: GetVLifeEditSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  return {
    fields: [
      {
        name: 'dateOfLifecycleEvent',
        label: t('lifecycle/uninstallation/date'),
        component: componentTypes.DATE_PICKER,
        isRequired: true,
        initialValue: lifecycleEvent?.dateOfLifecycleEvent ?? DateTime.now().toISODate(),
        validateOnMount: true,
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
        ],
      },
      {
        name: 'personId',
        isSearchable: true,
        sortAlphabetical: true,
        label: t('lifecycle/uninstallation/person'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: people?.map((person) => ({
          id: person.id,
          name: formatPerson(person),
        })),
        initialValue: {
          id: lifecycleEvent?.person?.id,
          name: formatPerson(lifecycleEvent?.person),
        },
        readPermission: RBACPermissions.CONTACT_READ,
        writePermission: RBACPermissions.CONTACT_WRITE,
      },
      {
        name: 'uninstallStatusId',
        label: t('lifecycle/uninstallation/status'),
        isClearable: true,
        isSearchable: true,
        isAlphabetical: true,
        component: componentTypes.SELECT,
        options: uninstallStatuses,
        initialValue: lifecycleEvent?.uninstallStatus,
      },
      {
        name: 'notes',
        label: t('lifecycle/uninstallation/notes'),
        component: componentTypes.TEXTAREA,
        initialValue: lifecycleEvent?.notes,
        sx: { gridColumnEnd: 'span 2' },
      },
    ],
  };
};
