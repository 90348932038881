import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from '~/components/Toast/Toast';

import { UploadDialog } from '~/components/Upload/UploadDialog';
import { PositionedUploadVLogsButton } from '~/features/v-logs/components/PositionedUploadVLogsButton';
import { useUploadVLogs } from '~/features/v-logs/hooks/useUploadVLogs';

export interface UploadVLogsButtonProps {
  isRenderedAtPageLevel: boolean;
}

export const UploadVLogsButton = ({ isRenderedAtPageLevel }: UploadVLogsButtonProps) => {
  const { t } = useTranslation('v-log');
  const { uploadFiles } = useUploadVLogs();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [displayToast, setDisplayToast] = useState(false);

  return (
    <>
      <PositionedUploadVLogsButton
        isRenderedAtPageLevel={isRenderedAtPageLevel}
        setUploadDialogOpen={setUploadDialogOpen}
      />
      <Toast severity='success' open={displayToast} onClose={() => setDisplayToast(false)}>
        {t('load-in-progress')}
      </Toast>
      <UploadDialog
        open={uploadDialogOpen}
        onCancel={() => setUploadDialogOpen(false)}
        onUpload={(files) => {
          setUploadDialogOpen(false);
          uploadFiles(files);
          setDisplayToast(true);
        }}
      />
    </>
  );
};
