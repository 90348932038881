import { useCallback } from 'react';
import { Tabs, Tab } from '@mui/material';
import { TabPanel } from '~/components/TabPanel/TabPanel';
import { useTranslation } from 'react-i18next';
import { VLifeReportForms } from '../../components/VLifeReportForms';
import { PendingVLifeReports } from '../../components/PendingVLifeReports';
import { useLocation, useNavigate } from 'react-router-dom';
import { Path } from '~/routes/paths';

export const ListVLifeReports: React.FC = () => {
  const { t } = useTranslation('v-life-reports');
  const navigate = useNavigate();
  /**
   * Instead of storing the tab number in state, use the URL (location.pathname)
   * to indicate which tab we're on, and use navigate to change tabs.
   */
  const location = useLocation();
  const handleTabChange = useCallback(
    (_e: React.SyntheticEvent, newValue: string) => navigate(newValue),
    [navigate]
  );

  return (
    <>
      <Tabs
        value={location.pathname}
        onChange={handleTabChange}
        aria-label={`tab-${location.pathname}`}
        sx={{ mb: 4 }}
      >
        {/** The pending reports tab is the first tab, so we don't use a sub-path for it */}
        <Tab label={t('pending')} value={Path.V_LIFE_REPORT_PENDING_LIST_PATH} />
        <Tab label={t('review')} value={Path.V_LIFE_REPORT_REVIEW_LIST_PATH} />
        <Tab label={t('approve')} value={Path.V_LIFE_REPORT_APPROVE_LIST_PATH} />
        <Tab label={t('publish')} value={Path.V_LIFE_REPORT_PUBLISH_LIST_PATH} />
      </Tabs>
      <TabPanel index={Path.V_LIFE_REPORT_PENDING_LIST_PATH} value={location.pathname}>
        <PendingVLifeReports />
      </TabPanel>
      <TabPanel index={Path.V_LIFE_REPORT_REVIEW_LIST_PATH} value={location.pathname}>
        <VLifeReportForms statePersistencyId='review-v-life-reports' />
      </TabPanel>
      <TabPanel index={Path.V_LIFE_REPORT_APPROVE_LIST_PATH} value={location.pathname}>
        <VLifeReportForms statePersistencyId='approve-v-life-reports' />
      </TabPanel>
      <TabPanel index={Path.V_LIFE_REPORT_PUBLISH_LIST_PATH} value={location.pathname}>
        <VLifeReportForms statePersistencyId='publish-v-life-reports' />
      </TabPanel>
    </>
  );
};
