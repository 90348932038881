import { Path, buildPath } from '~/routes/paths';

export type CustomerAssetTab = { path: string; label: string };

export type CustomerAssetTabs = Record<
  'overview' | 'logs' | 'reports' | 'graph' | string,
  CustomerAssetTab
>;

export function getTabs(id: string): CustomerAssetTabs {
  return {
    graph: {
      path: buildPath(Path.CUSTOMER_ASSET_GRAPH_PATH, { id }),
      label: 'graph',
    },
    overview: {
      path: buildPath(Path.CUSTOMER_ASSET_OVERVIEW_PATH, { id }),
      label: 'overview',
    },
    logs: {
      path: buildPath(Path.CUSTOMER_ASSET_LOGS_PATH, { id }),
      label: 'logs',
    },
    reports: {
      path: buildPath(Path.CUSTOMER_ASSET_REPORT_PATH, { id }),
      label: 'reports',
    },
  };
}
