import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import { env } from '~/config/environmentVariables';

const configuration: Configuration = {
  auth: {
    clientId: env.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: `https://login.microsoftonline.com/${env.REACT_APP_TENANT_ID}`, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    clientCapabilities: ['CP1'], // this lets the resource owner know that this client is capable of handling claims challenge.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const protectedResources = {
  apis: {
    scopes: {
      measurement: [`api://${env.REACT_APP_API_CLIENT_ID}/.default`],
      asset: [`api://${env.REACT_APP_API_CLIENT_ID}/.default`],
      dataRetrieval: [`api://${env.REACT_APP_API_CLIENT_ID}/.default`],
    },
  },
};

export const pca = new PublicClientApplication(configuration);

// Default to using the first account if no account is active on page load
if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  pca.setActiveAccount(pca.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
pca.enableAccountStorageEvents();

function isLoginSuccessEvent(
  event: EventMessage
): event is EventMessage & { payload: AuthenticationResult } {
  return event.eventType === EventType.LOGIN_SUCCESS;
}

// Listen for sign-in event and set active account
pca.addEventCallback((event) => {
  if (isLoginSuccessEvent(event) && event.payload.account) {
    const account = event.payload.account;
    pca.setActiveAccount(account);
  }
});

export async function getToken(scopes: string[]) {
  const account = pca.getActiveAccount();

  if (!account) {
    throw Error('No active account');
  }
  try {
    const response = await pca.acquireTokenSilent({
      account,
      scopes,
    });
    return response.accessToken;
  } catch (e) {
    console.log(e);
    if (e instanceof InteractionRequiredAuthError) {
      await pca.acquireTokenRedirect({
        account,
        scopes,
      });
    } else {
      console.error(e);
    }
  }
}
