import { createBrowserRouter } from 'react-router-dom';
import { App } from '~/App';
import { ErrorPage } from '~/features/error/ErrorPage/ErrorPage';
import { appRoutes } from './appRoutes';
import { Path } from './paths';

export const routes = [
  {
    path: Path.HOME,
    element: <App />,
    id: 'Home',
    children: appRoutes,
    errorElement: <ErrorPage />,
  },
];

export const router = createBrowserRouter(routes);
