import React from 'react';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { FormSpyWithBlocker } from '~/components/Form/FormSpyWithBlocker/FormSpyWithBlocker';
import { ViewPane } from '~/components/Form/ViewPane/ViewPane';

interface EditPaneContainerProps {
  editable: boolean;
  title?: React.ReactNode;
  children: React.ReactNode;
  saving: boolean;
  saveButtonLabel?: string;
}

export const EditPaneContainer = ({
  editable = true,
  title,
  children,
  saving,
  saveButtonLabel,
}: EditPaneContainerProps) => {
  const { submit, onCancel, getState } = useFormApi();

  return (
    <FormSpyWithBlocker onCancel={({ values }) => onCancel?.(values)} disableBlocker={saving}>
      {({ handleCancel }) => {
        return (
          <ViewPane
            editable={editable}
            editingThisSection={true}
            editingAnotherSection={false}
            onCancelClicked={handleCancel}
            onSaveClicked={submit}
            title={title}
            saving={saving}
            saveButtonLabel={saveButtonLabel}
            disableSave={getState().invalid}
          >
            {children}
          </ViewPane>
        );
      }}
    </FormSpyWithBlocker>
  );
};
