import { Path } from './paths';
import { CreateVLifeLicence } from '~/features/customer-asset/pages/create-v-life-licence/CreateVLifeLicence';
import { ListViperAssets } from '~/features/viper-asset/pages/list/ListViperAssets';
import { ViewViperAsset } from '~/features/viper-asset/pages/view/ViewViperAsset';
import { CreateViperAsset } from '~/features/viper-asset/pages/create/CreateViperAsset';
import { Navigate, RouteObject } from 'react-router-dom';
import { ListCompanies } from '~/features/companies/pages/list/ListCompanies';
import { CreateCompany } from '~/features/companies/pages/create/CreateCompany';
import { ViewUserProfile } from '~/features/user-profile/pages/ViewUserProfile';
import { ListContactsPage } from '~/features/contacts/pages/list/ListContactsPage';
import { CreateContactPage } from '~/features/contacts/pages/create/CreateContactPage';
import { CreateVLifeReport } from '~/features/v-life-reports/pages/create/CreateVLifeReport';
import { ReviewVLifeReport } from '~/features/v-life-reports/pages/review/ReviewVLifeReport';
import { PublishVLifeReport } from '~/features/v-life-reports/pages/publish/PublishVLifeReport';
import { StaticContent } from '~/components/StaticContent/StaticContent';
import { ApproveVLifeReport } from '~/features/v-life-reports/pages/approve/ApproveVLifeReport';
import { LicenceInsightsPage } from '~/features/licence-insights/pages/LicenceInsightsPage';
import { ListCustomerAssets } from '~/features/customer-asset/pages/list/ListCustomerAssets';
import { CreateCustomerAsset } from '~/features/customer-asset/pages/create/CreateCustomerAsset';
import { ViewCustomerAsset } from '~/features/customer-asset/pages/view/ViewCustomerAsset';
import { ListVLifeReports } from '~/features/v-life-reports/pages/list/ListVLifeReports';
import { Dashboard } from '~/features/dashboard/pages/Dashboard';
import { ListVLogsPage } from '~/features/v-logs/pages/ListVLogsPage';
import { UnauthorizedPage } from '~/features/error/Unauthorized/UnauthorizedPage';
import { IfPermitted } from '~/authorization/utils/IfPermitted';
import { RBACPermissions } from '~/authorization/permissions';

export const appRoutes: RouteObject[] = [
  {
    path: Path.HOME,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.DASHBOARD_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <Dashboard />
      </IfPermitted>
    ),
    id: 'Dashboard',
  },
  {
    id: 'Assets',
    children: [
      {
        path: Path.VIPER_ASSET_LIST_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.VIPER_ASSET_LIST_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ListViperAssets />
          </IfPermitted>
        ),
        id: 'ViperAssets',
      },
      {
        path: Path.VIPER_ASSET_OVERVIEW_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.VIPER_ASSET_OVERVIEW_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ViewViperAsset />
          </IfPermitted>
        ),
        id: 'ViperAssetOverview',
      },
      {
        path: Path.VIPER_ASSET_LOGS_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.VIPER_ASSET_LOGS_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ViewViperAsset />
          </IfPermitted>
        ),
        id: 'ViperAssetLogs',
      },
      {
        path: Path.VIPER_ASSET_CREATE_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.VIPER_ASSET_CREATE_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <CreateViperAsset />,
          </IfPermitted>
        ),
        id: 'CreateViperAsset',
      },
      {
        path: Path.CUSTOMER_ASSET_LIST_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.CUSTOMER_ASSET_LIST_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ListCustomerAssets />
          </IfPermitted>
        ),

        id: 'CustomerAssets',
      },
      {
        path: Path.CUSTOMER_ASSET_CREATE_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.CUSTOMER_ASSET_CREATE_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <CreateCustomerAsset />
          </IfPermitted>
        ),
        id: 'CreateCustomerAsset',
      },
      {
        path: Path.CUSTOMER_ASSET_OVERVIEW_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.CUSTOMER_ASSET_OVERVIEW_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ViewCustomerAsset />
          </IfPermitted>
        ),
        id: 'CustomerAssetOverview',
      },
      {
        path: Path.CUSTOMER_ASSET_LOGS_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.CUSTOMER_ASSET_LOGS_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ViewCustomerAsset />
          </IfPermitted>
        ),
        id: 'CustomerAssetLogs',
      },
      {
        path: Path.CUSTOMER_ASSET_REPORT_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.CUSTOMER_ASSET_REPORTS_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ViewCustomerAsset />
          </IfPermitted>
        ),
        id: 'CustomerAssetReports',
      },
      {
        path: Path.CUSTOMER_ASSET_GRAPH_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.CUSTOMER_ASSET_GRAPH_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ViewCustomerAsset />
          </IfPermitted>
        ),
        id: 'CustomerAssetGraph',
      },
      {
        path: Path.V_LIFE_LICENCE_CREATE_PATH,
        element: <CreateVLifeLicence />,
        id: 'CreateLicence',
      },
    ],
  },
  {
    id: 'VLifeService',
    children: [
      {
        path: Path.V_LIFE_REPORT_PENDING_LIST_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.V_LIFE_REPORT_PENDING_LIST_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ListVLifeReports />
          </IfPermitted>
        ),
        id: 'VLifeReportsPendingList',
      },
      {
        path: Path.V_LIFE_REPORT_REVIEW_LIST_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.V_LIFE_REPORT_REVIEW_LIST_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ListVLifeReports />
          </IfPermitted>
        ),
        id: 'VLifeReportsReviewList',
      },
      {
        path: Path.V_LIFE_REPORT_APPROVE_LIST_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.V_LIFE_REPORT_APPROVE_LIST_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ListVLifeReports />
          </IfPermitted>
        ),
        id: 'VLifeReportsApproveList',
      },
      {
        path: Path.V_LIFE_REPORT_PUBLISH_LIST_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.V_LIFE_REPORT_PUBLISH_LIST_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <ListVLifeReports />
          </IfPermitted>
        ),
        id: 'VLifeReportsPublishList',
      },
      {
        path: Path.LICENCE_INSIGHTS_PATH,
        element: (
          <IfPermitted
            requiredPermissions={[RBACPermissions.LICENCE_INSIGHTS_PAGE]}
            elseRender={<UnauthorizedPage />}
          >
            <LicenceInsightsPage />
          </IfPermitted>
        ),
        id: 'LicenceInsights',
      },
    ],
  },
  {
    path: `${Path.V_LIFE_REPORT_REVIEW_LIST_PATH}/create`,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.V_LIFE_REPORT_CREATE_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <Navigate to={Path.V_LIFE_REPORT_REVIEW_LIST_PATH} replace />
      </IfPermitted>
    ),
    id: 'CreateVLifeReportsRedirect',
  },
  {
    path: Path.V_LIFE_REPORT_PENDING_LIST_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.V_LIFE_REPORT_REVIEW_LIST_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <ListVLifeReports />
      </IfPermitted>
    ),
    id: 'ReviewVLifeReports',
  },
  {
    path: Path.V_LIFE_REPORT_PENDING_LIST_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.V_LIFE_REPORT_APPROVE_LIST_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <ListVLifeReports />
      </IfPermitted>
    ),
    id: 'ApproveVLifeReports',
  },
  {
    path: Path.V_LIFE_REPORT_PENDING_LIST_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.V_LIFE_REPORT_PUBLISH_LIST_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <ListVLifeReports />
      </IfPermitted>
    ),
    id: 'PublishVLifeReports',
  },
  {
    path: Path.V_LIFE_REPORT_CREATE_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.V_LIFE_REPORT_CREATE_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <CreateVLifeReport />
      </IfPermitted>
    ),
    id: 'CreateVLifeReport',
  },
  {
    path: Path.V_LIFE_REPORT_REVIEW_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.V_LIFE_REPORT_REVIEW_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <ReviewVLifeReport />
      </IfPermitted>
    ),
    id: 'ReviewVLifeReport',
  },
  {
    path: Path.V_LIFE_REPORT_APPROVE_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.V_LIFE_REPORT_APPROVE_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <ApproveVLifeReport />
      </IfPermitted>
    ),
    id: 'ApproveVLifeReport',
  },
  {
    path: Path.V_LIFE_REPORT_PUBLISH_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.V_LIFE_REPORT_PUBLISH_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <PublishVLifeReport />
      </IfPermitted>
    ),
    id: 'PublishVLifeReport',
  },
  {
    path: Path.V_LOG_LIST_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.V_LOG_LIST_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <ListVLogsPage />
      </IfPermitted>
    ),
    id: 'VLogs ',
  },
  {
    path: Path.COMPANY_LIST_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.COMPANY_LIST_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <ListCompanies />
      </IfPermitted>
    ),
    id: 'Companies',
  },
  {
    path: Path.COMPANY_CREATE_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.COMPANY_CREATE_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <CreateCompany />
      </IfPermitted>
    ),
    id: 'CreateCompany',
  },
  {
    path: Path.CONTACT_LIST_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.CONTACT_LIST_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <ListContactsPage />
      </IfPermitted>
    ),
    id: 'Contacts',
  },
  {
    path: Path.CONTACT_CREATE_PATH,
    element: (
      <IfPermitted
        requiredPermissions={[RBACPermissions.CONTACT_CREATE_PAGE]}
        elseRender={<UnauthorizedPage />}
      >
        <CreateContactPage />
      </IfPermitted>
    ),
    id: 'CreateContact',
  },
  {
    path: Path.COOKIE_POLICY_PATH,
    element: (
      <StaticContent
        content='cookie-policy'
        translationParams={{ userProfileRoute: Path.USER_PROFILE_PATH }}
      />
    ),
    id: 'CookiePolicy',
  },
  {
    path: Path.USER_PROFILE_PATH,
    element: <ViewUserProfile />,
    id: 'UserProfile',
  },
  {
    path: Path.UNAUTHORIZED_PATH,
    element: <UnauthorizedPage />,
    id: 'Unauthorised',
  },
];
