import React from 'react';
import { CreateMaintenanceLogDialog } from './dialogs/CreateMaintenanceLogDialog';
import { UpdateMaintenanceLogDialog } from './dialogs/UpdateMaintenanceLogDialog';
import { Box, Button, Typography } from '@mui/material';
import { Table } from '~/components/Table/Table';
import { useMaintenanceLog } from '../hooks/useMaintenanceLog';
import { ACTION_EDIT } from '~/components/ActionMenu/ActionMenu';
import { GetViperAssetQuery } from '~/graphql/generated/asset/graphql';
import { useTranslation } from 'react-i18next';
import { IfPermitted } from '~/authorization/utils/IfPermitted';
import { RBACPermissions } from '~/authorization/permissions';

const actionMenu = {
  label: 'actions',
  actionMenu: [ACTION_EDIT],
};

export const MaintenanceLog: React.FC<{ viperAsset: GetViperAssetQuery['viperAsset'] }> = ({
  viperAsset,
}) => {
  const { t } = useTranslation('viper-asset');

  const {
    handleMaintenanceLogActionClick,
    setShowCreateMaintenanceLogDialog,
    setShowMaintenanceLogDialog,
    showCreateMaintenanceLogDialog,
    showMaintenanceLogDialog,
    maintenanceLogs,
    maintenanceLogsDetails,
    id,
  } = useMaintenanceLog(viperAsset);

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
        <Typography variant='h3'>{t('maintenance-logs')}</Typography>
        <IfPermitted requiredPermissions={[RBACPermissions.VIPER_ASSET_WRITE]}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setShowCreateMaintenanceLogDialog(true)}
          >
            {t('create-maintenance-log')}
          </Button>
        </IfPermitted>
      </Box>
      <Table
        data={maintenanceLogs}
        omittedKeys={['_id']}
        translationKey={'viper-asset'}
        nestedData={maintenanceLogsDetails}
        actionMenu={actionMenu}
        onActionClick={handleMaintenanceLogActionClick}
      />
      <CreateMaintenanceLogDialog
        open={showCreateMaintenanceLogDialog}
        onClose={() => {
          setShowCreateMaintenanceLogDialog(false);
        }}
        id={id}
      />
      <UpdateMaintenanceLogDialog
        open={!!showMaintenanceLogDialog}
        onClose={() => {
          setShowMaintenanceLogDialog(undefined);
        }}
        id={id}
        maintenanceLogs={viperAsset?.maintenanceLogs}
        editId={showMaintenanceLogDialog}
      />
    </>
  );
};
