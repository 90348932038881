import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { formatDate } from '~/utils/date-utils';
import { GetViperAssetQuery } from '~/graphql/generated/asset/graphql';
import { usePastLifecycleEvents } from '~/features/viper-asset/hooks/lifecycle-history-table/usePastLifecycleEvents';

export const useViperAssetLifecycleTableColumns = (
  viperAsset: GetViperAssetQuery['viperAsset']
) => {
  const { t } = useTranslation('viper-asset-forms');
  const { pastLifecycleEvents } = usePastLifecycleEvents(viperAsset);

  const lifeCycleHistory: Record<string, unknown>[] | undefined = useMemo(
    () =>
      pastLifecycleEvents?.map((lifecycleEvent) => ({
        _id: lifecycleEvent?.id,
        [t('lifecycle/history/status')]: t(
          `viper-assets::installation-status/${lifecycleEvent?.eventType}`
        ),
        [t('lifecycle/history/date')]: formatDate(lifecycleEvent?.dateOfLifecycleEvent),
      })),
    [t, pastLifecycleEvents]
  );

  return { lifecycleHistoryTableColumns: lifeCycleHistory };
};
