import { useCallback } from 'react';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import FormTemplate from '@data-driven-forms/mui-component-mapper/form-template';
import {
  ComponentMapper,
  Field,
  FormTemplateRenderProps,
  Schema,
} from '@data-driven-forms/react-form-renderer';
import { WizardInternal } from './components/InternalWizard';
import { WizardProps } from '@data-driven-forms/mui-component-mapper/wizard';
import { Dialog } from '~/components/Dialog/Dialog';
import { Warning } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useWizard } from './useWizard';
import { useTranslation } from 'react-i18next';
import { WizardSubmittingSkeleton } from '~/components/LoadingSkeleton/LoadingSkeleton';
import { validatorMapper } from '../utils/validators';
import { componentMapper as defaultComponentMapper } from '../formFields/mappers/componentMapper';
import { useGuardCreateWizardSchema } from '~/components/Form/Wizard/useGuardCreateWizardSchema';

export interface CreateWizardProps {
  schema: Schema;
  componentMapper?: ComponentMapper;
  onSubmit: (values: Record<string, unknown>) => Promise<void> | void;
  onCancel?: () => void;
  validate?: (values: Record<string, unknown>) => { name: string } | undefined;
  loading?: boolean;
  saving?: boolean;
}

export const CreateWizard = ({
  schema,
  componentMapper = defaultComponentMapper,
  onSubmit,
  onCancel,
  validate,
  loading,
  saving,
}: CreateWizardProps) => {
  const { t } = useTranslation('wizard');

  const { guardedSchema } = useGuardCreateWizardSchema(schema);

  const {
    showDialog,
    presentationalSchema,
    showMandatory,
    setShowMandatory,
    handleSubmit,
    handleCloseDialog,
    handleDialog,
    handleCancel,
  } = useWizard(guardedSchema, onSubmit, onCancel);

  const wizard = useCallback(
    (props: WizardProps & { fields: Field[] }) =>
      loading || showDialog ? (
        <WizardSubmittingSkeleton />
      ) : (
        <WizardInternal
          {...props}
          saving={saving}
          showMandatory={showMandatory}
          onShowMandatoryClicked={() => setShowMandatory((s: boolean) => !s)}
        />
      ),
    [loading, showDialog, showMandatory, setShowMandatory, saving]
  );

  const template = useCallback(
    (props: FormTemplateRenderProps) => <FormTemplate {...props} showFormControls={false} />,
    []
  );

  return (
    <>
      <FormRenderer
        FormTemplate={template}
        validate={validate}
        validatorMapper={validatorMapper}
        schema={presentationalSchema ?? []}
        componentMapper={{ ...componentMapper, wizard }}
        subscription={{ values: true }}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <Dialog
        open={showDialog}
        onConfirm={handleDialog}
        onClose={handleCloseDialog}
        confirmText={t('confirm-button')}
        cancelText={t('cancel-button')}
        content={
          <Stack direction='row' gap={1}>
            <Warning sx={{ color: (theme) => theme.palette.warning.main }} />{' '}
            {t('confirm-text')}
          </Stack>
        }
      />
    </>
  );
};
