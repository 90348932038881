import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { useGuardVariables } from '~/graphql/authorization/useGuardVariables';

export const useGuardedMutation = <
  TData = unknown,
  TContext = DefaultContext,
  TCache extends ApolloCache<unknown> = ApolloCache<unknown>
>(
  mutation: DocumentNode | TypedDocumentNode<TData, OperationVariables>,
  options?: MutationHookOptions<TData, OperationVariables, TContext, TCache>
): MutationTuple<TData, OperationVariables, TContext, TCache> => {
  const { guardVariables } = useGuardVariables(mutation);

  if (options?.variables) {
    options.variables = guardVariables(options.variables);
  }

  const [originalMutation, result] = useMutation(mutation, options);

  const wrappedMutation = (
    options: MutationFunctionOptions<TData, OperationVariables, TContext, TCache> | undefined
  ) => {
    if (options?.variables) {
      options.variables = guardVariables(options.variables);
    }

    return originalMutation(options);
  };

  return [wrappedMutation, result];
};
