import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { CustomButtonProps } from '~/components/Form/CustomButtonProps';

export const FooterActions: React.FC<{
  onSaveClicked?: () => void;
  onCancelClicked?: () => void;
  onEditClicked?: () => void;
  editingThisSection: boolean;
  editingAnotherSection: boolean;
  saving: boolean;
  saveButtonLabel?: string;
  disableSave?: boolean;
  customButton?: CustomButtonProps;
}> = ({
  onSaveClicked,
  onCancelClicked,
  onEditClicked,
  editingThisSection,
  editingAnotherSection,
  saving,
  saveButtonLabel,
  disableSave,
  customButton,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      {customButton && (
        <Button
          key='custom-button'
          onClick={() => {
            if (customButton.to) {
              navigate(customButton.to);
            }
            customButton.onCustomButtonClick?.();
          }}
          {...customButton}
        >
          {customButton.label}
        </Button>
      )}
      {editingThisSection ? (
        <>
          <Button
            // add keys to these buttons, otherwise react tries to reuse the cancel button
            // for the edit button and vice versa, and you get an odd flash of colour as
            // the component is updated
            key='cancel'
            variant='contained'
            color='neutral'
            onClick={onCancelClicked}
            disabled={saving}
          >
            {t('viper::cancel')}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={onSaveClicked}
            disabled={saving || disableSave}
          >
            {saveButtonLabel || t('viper::save')}
          </Button>
        </>
      ) : (
        <>
          <Button
            key='edit'
            variant='contained'
            color='primary'
            onClick={onEditClicked}
            disabled={editingAnotherSection}
          >
            {t('viper::edit')}
          </Button>
        </>
      )}
    </>
  );
};
