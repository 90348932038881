import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { RBACPermission } from '~/authorization/permissions';
import { useGetUserPermissions } from '~/authorization/queries/useGetUserPermissions';

export const RBACContext = createContext<RBACPermission[] | undefined>(undefined);

export const RBACProvider = ({ children }: PropsWithChildren) => {
  const [permissions, setPermissions] = useState<RBACPermission[] | undefined>();

  // TODO: Replace permissions retrieved from `useGetCurrentUser` returned roles to something else
  // Fetch list of user permissions
  const { data, loading } = useGetUserPermissions({ skip: permissions !== undefined });

  useEffect(() => {
    if (!loading && data) {
      const retrievedPermissions = data.currentUser?.roles ?? [];

      setPermissions([...retrievedPermissions] as RBACPermission[]);
    }
  }, [data, loading]);

  return <RBACContext.Provider value={permissions}>{children}</RBACContext.Provider>;
};
