import { Permission as AssetApiPermission } from '~/graphql/generated/asset/graphql';
import { Permission as MeasurementApiPermission } from '~/graphql/generated/measurement/graphql';

// Convert AssetApiPermission to an object where the names are in SCREAMING_SNAKE_CASE
// as well as the values.
const AssetPermissions = Object.fromEntries(
  Object.values(AssetApiPermission).map((value) => [value, value])
) as { [K in AssetApiPermission]: K };

// Convert MeasurementApiPermission to an object where the names are in SCREAMING_SNAKE_CASE
// as well as the values.
const MeasurementPermissions = Object.fromEntries(
  Object.values(MeasurementApiPermission).map((value) => [value, value])
) as { [K in MeasurementApiPermission]: K };

const PageGuardPermissions = {
  DASHBOARD_PAGE: 'DASHBOARD_PAGE',
  VIPER_ASSET_LIST_PAGE: 'VIPER_ASSET_LIST_PAGE',
  VIPER_ASSET_OVERVIEW_PAGE: 'VIPER_ASSET_OVERVIEW_PAGE',
  VIPER_ASSET_LOGS_PAGE: 'VIPER_ASSET_LOGS_PAGE',
  VIPER_ASSET_CREATE_PAGE: 'VIPER_ASSET_CREATE_PAGE',
  CUSTOMER_ASSET_LIST_PAGE: 'CUSTOMER_ASSET_LIST_PAGE',
  CUSTOMER_ASSET_OVERVIEW_PAGE: 'CUSTOMER_ASSET_OVERVIEW_PAGE',
  CUSTOMER_ASSET_LOGS_PAGE: 'CUSTOMER_ASSET_LOGS_PAGE',
  CUSTOMER_ASSET_REPORTS_PAGE: 'CUSTOMER_ASSET_REPORTS_PAGE',
  CUSTOMER_ASSET_GRAPH_PAGE: 'CUSTOMER_ASSET_GRAPH_PAGE',
  CUSTOMER_ASSET_CREATE_PAGE: 'CUSTOMER_ASSET_CREATE_PAGE',
  V_LIFE_LICENCE_CREATE_PAGE: 'V_LIFE_LICENCE_CREATE_PAGE',
  V_LIFE_REPORT_PENDING_LIST_PAGE: 'V_LIFE_REPORT_PENDING_LIST_PAGE',
  V_LIFE_REPORT_REVIEW_LIST_PAGE: 'V_LIFE_REPORT_REVIEW_LIST_PAGE',
  V_LIFE_REPORT_APPROVE_LIST_PAGE: 'V_LIFE_REPORT_APPROVE_LIST_PAGE',
  V_LIFE_REPORT_PUBLISH_LIST_PAGE: 'V_LIFE_REPORT_PUBLISH_LIST_PAGE',
  V_LIFE_REPORT_CREATE_PAGE: 'V_LIFE_REPORT_CREATE_PAGE',
  V_LIFE_REPORT_REVIEW_PAGE: 'V_LIFE_REPORT_REVIEW_PAGE',
  V_LIFE_REPORT_APPROVE_PAGE: 'V_LIFE_REPORT_APPROVE_PAGE',
  V_LIFE_REPORT_PUBLISH_PAGE: 'V_LIFE_REPORT_PUBLISH_PAGE',
  LICENCE_INSIGHTS_PAGE: 'LICENCE_INSIGHTS_PAGE',
  V_LOG_LIST_PAGE: 'V_LOG_LIST_PAGE',
  COMPANY_LIST_PAGE: 'COMPANY_LIST_PAGE',
  COMPANY_CREATE_PAGE: 'COMPANY_CREATE_PAGE',
  CONTACT_LIST_PAGE: 'CONTACT_LIST_PAGE',
  CONTACT_CREATE_PAGE: 'CONTACT_CREATE_PAGE',
} as const;

export const RBACPermissions = {
  ...AssetPermissions,
  ...MeasurementPermissions,
  ...PageGuardPermissions,
} as const;

export type RBACPermission = typeof RBACPermissions[keyof typeof RBACPermissions];
