import { GetViperAssetQuery, LifecycleEventType } from '~/graphql/generated/asset/graphql';
import { buildPath, Path } from '~/routes/paths';
import { Highlight } from '~/features/viper-asset/components/Highlights';
import { useTranslation } from 'react-i18next';
import { RBACPermissions } from '~/authorization/permissions';

export const useHighlightsSchema = ({
  channel,
}: {
  // @ts-expect-error type is too deep for TS
  channel?: GetViperAssetQuery['viperAsset']['latestLifecycleEvent']['channel'];
}) => {
  const { t } = useTranslation('viper-asset-forms');
  const path = buildPath(Path.CUSTOMER_ASSET_GRAPH_PATH, { id: channel?.id });
  return {
    [LifecycleEventType.Installation]: [
      {
        title: t('status'),
        description: t('installed'),
        icon: 'secondary' as Highlight['icon'],
      },
      {
        title: t('field'),
        description: channel?.field?.name,
        readPermission: RBACPermissions.FIELD_READ,
      },
      {
        title: t('channel'),
        description: channel?.name,
        to: path,
        readPermission: RBACPermissions.CUSTOMER_ASSET_READ,
      },
    ],
    [LifecycleEventType.Commissioning]: [
      {
        title: t('status'),
        description: t('commissioned'),
        icon: 'success' as Highlight['icon'],
      },
      {
        title: t('field'),
        description: channel?.field?.name,
        readPermission: RBACPermissions.FIELD_READ,
      },
      {
        title: t('channel'),
        description: channel?.name,
        to: path,
        readPermission: RBACPermissions.CUSTOMER_ASSET_READ,
      },
    ],
    [LifecycleEventType.Decommissioning]: [
      {
        title: t('status'),
        description: t('decommissioned'),
        icon: 'warning' as Highlight['icon'],
      },
      {
        title: t('field'),
        description: channel?.field?.name,
        readPermission: RBACPermissions.FIELD_READ,
      },
      {
        title: t('channel'),
        description: channel?.name,
        to: path,
        readPermission: RBACPermissions.CUSTOMER_ASSET_READ,
      },
    ],
    [LifecycleEventType.Uninstallation]: [
      {
        title: t('status'),
        description: t('uninstalled'),
        icon: 'neutral' as Highlight['icon'],
      },
    ],
  };
};
