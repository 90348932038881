import { GetViperAssetQuery } from '~/graphql/generated/asset/graphql';
import { useMemo } from 'react';

export const usePastLifecycleEvents = (viperAsset: GetViperAssetQuery['viperAsset']) => {
  const pastLifecycleEvents = useMemo(
    () => viperAsset?.lifecycleEvents.slice(1),
    [viperAsset?.lifecycleEvents]
  );

  return { pastLifecycleEvents };
};
