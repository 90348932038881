import Home from '@mui/icons-material/Home';
import Memory from '@mui/icons-material/Memory';
import {
  Business,
  MonitorHeartOutlined,
  PersonOutline,
  RocketLaunchOutlined,
} from '@mui/icons-material';
import { Path } from '~/routes/paths';
import { useRBAC } from '~/authorization/utils/useRBAC';
import { RBACPermission, RBACPermissions } from '~/authorization/permissions';

// t('nav/dashboard')
// t('nav/assets')
// t('nav/customer-assets')
// t('nav/create')
// t('nav/viper-assets')
// t('nav/create-licence')
// t('nav/companies')
// t('nav/contacts')
// t('nav/v-logs')
// t('nav/v-life-reports')
// t('nav/v-life-service')
// t('nav/review')
// t('nav/approve')
// t('nav/publish')
// t('nav/cookie-policy')
// t('nav/user-profile')
// t('nav/overview')
// t('nav/logs')
// t('nav/reports')

/* Static contents */
// t('static::cookie-policy')
// t('nav/licence-insights')

export type NavigationItem = {
  key: string | null;
  icon: JSX.Element | null;
  children?: NavigationItem[];
  path?: string;
};

export const useNavigationItems = () => {
  const { userHasSomePermissions } = useRBAC();

  const guardPath = (
    requiredAnyPermission: RBACPermission[],
    navigationItem: NavigationItem
  ): NavigationItem[] => {
    if (!userHasSomePermissions(requiredAnyPermission)) {
      return [];
    }
    return [navigationItem];
  };

  const items: NavigationItem[] = [
    ...guardPath([RBACPermissions.DASHBOARD_PAGE], {
      key: 'nav/dashboard',
      icon: <Home />,
      path: Path.HOME,
    }),
    ...guardPath(
      [RBACPermissions.VIPER_ASSET_LIST_PAGE, RBACPermissions.CUSTOMER_ASSET_LIST_PAGE],
      {
        key: 'nav/assets',
        icon: <Memory />,
        children: [
          ...guardPath([RBACPermissions.VIPER_ASSET_LIST_PAGE], {
            key: 'nav/viper-assets',
            icon: null,
            path: Path.VIPER_ASSET_LIST_PATH,
          }),
          ...guardPath([RBACPermissions.CUSTOMER_ASSET_LIST_PAGE], {
            key: 'nav/customer-assets',
            icon: null,
            path: Path.CUSTOMER_ASSET_LIST_PATH,
          }),
        ],
      }
    ),
    ...guardPath(
      [RBACPermissions.V_LIFE_REPORT_PENDING_LIST_PAGE, RBACPermissions.LICENCE_INSIGHTS_PAGE],
      {
        key: 'nav/v-life-service',
        icon: <MonitorHeartOutlined />,
        children: [
          ...guardPath([RBACPermissions.V_LIFE_REPORT_PENDING_LIST_PAGE], {
            key: 'nav/v-life-reports',
            icon: null,
            path: Path.V_LIFE_REPORT_PENDING_LIST_PATH,
          }),
          ...guardPath([RBACPermissions.LICENCE_INSIGHTS_PAGE], {
            key: 'nav/licence-insights',
            icon: null,
            path: Path.LICENCE_INSIGHTS_PATH,
          }),
        ],
      }
    ),
    ...guardPath([RBACPermissions.V_LOG_LIST_PAGE], {
      key: 'nav/v-logs',
      icon: <RocketLaunchOutlined />,
      path: Path.V_LOG_LIST_PATH,
    }),
    ...guardPath([RBACPermissions.COMPANY_LIST_PAGE], {
      key: 'nav/companies',
      icon: <Business />,
      path: Path.COMPANY_LIST_PATH,
    }),
    ...guardPath([RBACPermissions.CONTACT_LIST_PAGE], {
      key: 'nav/contacts',
      icon: <PersonOutline />,
      path: Path.CONTACT_LIST_PATH,
    }),
  ];

  return {
    items,
  };
};
