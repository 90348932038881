import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

export const UnauthorizedPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
        color: (theme) => theme.palette.grey[500],
        flexGrow: 1,
        height: '60vh',
        overflow: 'hidden',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
      }}
    >
      <Typography
        component='h1'
        variant='h1'
        sx={{ color: (theme) => theme.palette.grey[500] }}
      >
        <ErrorOutline fontSize='inherit' sx={{ mx: 'auto' }} />
      </Typography>
      <Typography variant='h2' component='h2' sx={{ mb: 2, color: 'inherit' }}>
        <b> {t('unauthorized')} </b>
      </Typography>
    </Box>
  );
};
