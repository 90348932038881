export type InputPermissions = Record<string, Record<string, {
  type: string,
  permission?: string
}>>;
export const inputPermissions: InputPermissions = {
  "ChannelCreateInput": {
    "alarm1Value": {
      "type": "Long"
    },
    "alarm2Value": {
      "type": "Long"
    },
    "couplerUsed": {
      "type": "String"
    },
    "couplingTypeId": {
      "type": "ID"
    },
    "customerId": {
      "type": "ID"
    },
    "dataDue": {
      "type": "Date"
    },
    "fieldId": {
      "type": "ID"
    },
    "interfaceCardId": {
      "type": "ID"
    },
    "latestFileReceived": {
      "type": "Date"
    },
    "lifetimeStartDate": {
      "type": "Date"
    },
    "name": {
      "type": "String"
    },
    "negativeImpactOnRebootNotes": {
      "type": "String"
    },
    "notes": {
      "type": "String"
    },
    "operatingVoltage": {
      "type": "Long"
    },
    "operatorId": {
      "type": "ID"
    },
    "platformId": {
      "type": "ID"
    },
    "poweringDeviceId": {
      "type": "ID"
    },
    "productionControlSystemId": {
      "type": "ID"
    },
    "referenceIR": {
      "type": "Long"
    },
    "relayConfigurationId": {
      "type": "ID"
    },
    "tripAlarmRelayId": {
      "type": "ID"
    },
    "umbilicalId": {
      "type": "ID"
    },
    "viperProjectNumber": {
      "type": "String"
    }
  },
  "ChannelUpdateInput": {
    "alarm1Value": {
      "type": "Long"
    },
    "alarm2Value": {
      "type": "Long"
    },
    "couplerUsed": {
      "type": "String"
    },
    "couplingTypeId": {
      "type": "ID"
    },
    "customerId": {
      "type": "NonNullID"
    },
    "dataDue": {
      "type": "Date"
    },
    "fieldId": {
      "type": "NonNullID"
    },
    "id": {
      "type": "ID"
    },
    "interfaceCardId": {
      "type": "ID"
    },
    "latestFileReceived": {
      "type": "Date"
    },
    "lifetimeStartDate": {
      "type": "Date"
    },
    "mostRecentVLifeConfig": {
      "type": "VLifeConfigUpdateInput",
      "permission": "V_LIFE_LICENCE_WRITE"
    },
    "name": {
      "type": "NonNullString"
    },
    "negativeImpactOnRebootNotes": {
      "type": "String"
    },
    "notes": {
      "type": "String"
    },
    "operatingVoltage": {
      "type": "Long"
    },
    "operatorId": {
      "type": "NonNullID"
    },
    "platformId": {
      "type": "ID"
    },
    "poweringDeviceId": {
      "type": "ID"
    },
    "productionControlSystemId": {
      "type": "ID"
    },
    "referenceIR": {
      "type": "Long"
    },
    "relayConfigurationId": {
      "type": "ID"
    },
    "tripAlarmRelayId": {
      "type": "ID"
    },
    "umbilicalId": {
      "type": "ID"
    },
    "upcomingVLifeConfig": {
      "type": "VLifeConfigUpdateInput",
      "permission": "V_LIFE_LICENCE_WRITE"
    },
    "viperProjectNumber": {
      "type": "String"
    }
  },
  "CustomerAssetMaintenanceLogCreateInput": {
    "affectedViperAssetIds": {
      "type": "ID"
    },
    "channelId": {
      "type": "ID"
    },
    "maintenanceEndDate": {
      "type": "Date"
    },
    "maintenanceStartDate": {
      "type": "Date"
    },
    "maintenanceTypeId": {
      "type": "ID"
    },
    "outcomeOfMaintenance": {
      "type": "String"
    },
    "personId": {
      "type": "ID"
    }
  },
  "CustomerAssetMaintenanceLogUpdateInput": {
    "affectedViperAssetIds": {
      "type": "Long"
    },
    "maintenanceEndDate": {
      "type": "NonNullDate"
    },
    "maintenanceStartDate": {
      "type": "NonNullDate"
    },
    "maintenanceTypeId": {
      "type": "NonNullID"
    },
    "outcomeOfMaintenance": {
      "type": "NonNullString"
    },
    "personId": {
      "type": "Long"
    }
  },
  "CustomerCreateInput": {
    "address": {
      "type": "String"
    },
    "countryId": {
      "type": "ID"
    },
    "headquarters": {
      "type": "String"
    },
    "name": {
      "type": "String"
    }
  },
  "CustomerUpdateInput": {
    "address": {
      "type": "String"
    },
    "countryId": {
      "type": "NonNullID"
    },
    "headquarters": {
      "type": "String"
    },
    "name": {
      "type": "NonNullString"
    }
  },
  "FieldCreateInput": {
    "countryId": {
      "type": "ID"
    },
    "defaultCustomerId": {
      "type": "ID"
    },
    "defaultOperatorId": {
      "type": "ID"
    },
    "name": {
      "type": "String"
    }
  },
  "FieldUpdateInput": {
    "countryId": {
      "type": "NonNullID"
    },
    "defaultCustomerId": {
      "type": "NonNullID"
    },
    "defaultOperatorId": {
      "type": "NonNullID"
    },
    "name": {
      "type": "NonNullString"
    }
  },
  "FirmwareUpdateCreateInput": {
    "comment": {
      "type": "String"
    },
    "dateOfUpdate": {
      "type": "Date"
    },
    "firmwareRevisionId": {
      "type": "ID"
    },
    "personId": {
      "type": "ID"
    },
    "viperAssetId": {
      "type": "ID"
    }
  },
  "FirmwareUpdateUpdateInput": {
    "comment": {
      "type": "String"
    },
    "dateOfUpdate": {
      "type": "NonNullDate"
    },
    "firmwareRevisionId": {
      "type": "NonNullID"
    },
    "personId": {
      "type": "ID"
    }
  },
  "LifecycleEventCreateInput": {
    "channelId": {
      "type": "ID"
    },
    "dateOfLifecycleEvent": {
      "type": "Date"
    },
    "decommissionReasonId": {
      "type": "ID"
    },
    "eventType": {
      "type": "LifecycleEventType"
    },
    "installationTypeId": {
      "type": "ID"
    },
    "notes": {
      "type": "String"
    },
    "personId": {
      "type": "ID"
    },
    "uninstallStatusId": {
      "type": "ID"
    },
    "viperAssetId": {
      "type": "ID"
    }
  },
  "LifecycleEventUpdateInput": {
    "dateOfLifecycleEvent": {
      "type": "NonNullDate"
    },
    "decommissionReasonId": {
      "type": "ID"
    },
    "installationTypeId": {
      "type": "ID"
    },
    "notes": {
      "type": "String"
    },
    "personId": {
      "type": "ID"
    },
    "uninstallStatusId": {
      "type": "ID"
    }
  },
  "MaintenanceLogCreateInput": {
    "maintenanceEndDate": {
      "type": "Date"
    },
    "maintenanceStartDate": {
      "type": "Date"
    },
    "maintenanceTypeId": {
      "type": "ID"
    },
    "outcomeOfMaintenance": {
      "type": "String"
    },
    "personId": {
      "type": "ID"
    },
    "viperAssetId": {
      "type": "ID"
    }
  },
  "MaintenanceLogUpdateInput": {
    "maintenanceEndDate": {
      "type": "NonNullDate"
    },
    "maintenanceStartDate": {
      "type": "NonNullDate"
    },
    "maintenanceTypeId": {
      "type": "NonNullID"
    },
    "outcomeOfMaintenance": {
      "type": "NonNullString"
    },
    "personId": {
      "type": "ID"
    }
  },
  "NonNullDate": {
    "value": {
      "type": "Date"
    }
  },
  "NonNullID": {
    "value": {
      "type": "ID"
    }
  },
  "NonNullLong": {
    "value": {
      "type": "Long"
    }
  },
  "NonNullString": {
    "value": {
      "type": "String"
    }
  },
  "PersonCreateInput": {
    "companyId": {
      "type": "ID"
    },
    "email": {
      "type": "String"
    },
    "forename": {
      "type": "String"
    },
    "position": {
      "type": "String"
    },
    "primaryTelephone": {
      "type": "String"
    },
    "secondaryTelephone": {
      "type": "String"
    },
    "surname": {
      "type": "String"
    }
  },
  "PersonUpdateInput": {
    "companyId": {
      "type": "NonNullID"
    },
    "email": {
      "type": "NonNullString"
    },
    "forename": {
      "type": "NonNullString"
    },
    "position": {
      "type": "String"
    },
    "primaryTelephone": {
      "type": "String"
    },
    "secondaryTelephone": {
      "type": "String"
    },
    "surname": {
      "type": "NonNullString"
    }
  },
  "PlatformCreateInput": {
    "name": {
      "type": "String"
    },
    "waterDepth": {
      "type": "Long"
    }
  },
  "PlatformUpdateInput": {
    "name": {
      "type": "NonNullString"
    },
    "waterDepth": {
      "type": "Long"
    }
  },
  "ProductionControlSystemCreateInput": {
    "electricalDistributionTypeId": {
      "type": "ID"
    },
    "electricalTopologyId": {
      "type": "ID"
    },
    "epuApproxInstallationDate": {
      "type": "Long"
    },
    "epuManufacturerId": {
      "type": "ID"
    },
    "frequency": {
      "type": "Long"
    },
    "maximumOperatingVoltage": {
      "type": "Long"
    },
    "pcsApproxInstallationDate": {
      "type": "Long"
    },
    "pcsManufacturerId": {
      "type": "ID"
    },
    "platformId": {
      "type": "ID"
    }
  },
  "ProductionControlSystemUpdateInput": {
    "electricalDistributionTypeId": {
      "type": "ID"
    },
    "electricalTopologyId": {
      "type": "ID"
    },
    "epuApproxInstallationDate": {
      "type": "Long"
    },
    "epuManufacturerId": {
      "type": "NonNullID"
    },
    "frequency": {
      "type": "Long"
    },
    "maximumOperatingVoltage": {
      "type": "Long"
    },
    "pcsApproxInstallationDate": {
      "type": "Long"
    },
    "pcsManufacturerId": {
      "type": "NonNullID"
    },
    "platformId": {
      "type": "NonNullID"
    }
  },
  "Sort": {
    "direction": {
      "type": "SortDirection"
    },
    "field": {
      "type": "String"
    }
  },
  "UmbilicalCreateInput": {
    "approxInstallationDate": {
      "type": "Long"
    },
    "length": {
      "type": "Long"
    },
    "manufacturerId": {
      "type": "ID"
    },
    "materialTypeId": {
      "type": "ID"
    },
    "platformId": {
      "type": "ID"
    }
  },
  "UmbilicalUpdateInput": {
    "approxInstallationDate": {
      "type": "Long"
    },
    "length": {
      "type": "NonNullLong"
    },
    "manufacturerId": {
      "type": "NonNullID"
    },
    "materialTypeId": {
      "type": "ID"
    },
    "platformId": {
      "type": "NonNullID"
    }
  },
  "VLifeConfigCreateInput": {
    "channelId": {
      "type": "ID"
    },
    "expiryDateCommToCustomer": {
      "type": "Date"
    },
    "expiryDateInConfig": {
      "type": "Date"
    },
    "notes": {
      "type": "String"
    },
    "startDate": {
      "type": "Date"
    },
    "vlifeLicence": {
      "type": "VLifeLicenceCreateInput"
    },
    "vlifeVersionId": {
      "type": "ID"
    }
  },
  "VLifeConfigUpdateInput": {
    "deactivationReasonId": {
      "type": "ID"
    },
    "disableDate": {
      "type": "Date"
    },
    "disabled": {
      "type": "Boolean"
    },
    "expiryDateCommToCustomer": {
      "type": "NonNullDate"
    },
    "expiryDateInConfig": {
      "type": "NonNullDate"
    },
    "flaggedForDecommission": {
      "type": "Boolean"
    },
    "flaggedForDecommissionDate": {
      "type": "Date"
    },
    "notes": {
      "type": "String"
    },
    "resumptionDate": {
      "type": "Date"
    },
    "startDate": {
      "type": "NonNullDate"
    },
    "vlifeLicence": {
      "type": "VLifeLicenceUpdateInput"
    },
    "vlifeVersionId": {
      "type": "NonNullID"
    }
  },
  "VLifeLicenceCreateInput": {
    "purchaseOrderNumber": {
      "type": "String"
    },
    "salesOrderNumber": {
      "type": "String"
    },
    "starjarProjectNumber": {
      "type": "String"
    },
    "vlifeLicenceTypeId": {
      "type": "ID"
    }
  },
  "VLifeLicenceUpdateInput": {
    "purchaseOrderNumber": {
      "type": "NonNullString"
    },
    "salesOrderNumber": {
      "type": "NonNullString"
    },
    "starjarProjectNumber": {
      "type": "NonNullString"
    },
    "vlifeLicenceTypeId": {
      "type": "NonNullID"
    }
  },
  "VLifeReportCreateInput": {
    "channelId": {
      "type": "ID"
    },
    "dateReleased": {
      "type": "Date"
    },
    "documentNumber": {
      "type": "String"
    },
    "endDate": {
      "type": "Date"
    },
    "name": {
      "type": "String"
    },
    "periodCovered": {
      "type": "Date"
    },
    "revision": {
      "type": "Long"
    },
    "source": {
      "type": "ReportSource"
    },
    "startDate": {
      "type": "Date"
    },
    "vlifeGrade": {
      "type": "ReportGrade"
    },
    "vlifeReportBlobId": {
      "type": "ID"
    }
  },
  "VLifeReportFormApproveInput": {
    "comment": {
      "type": "String"
    },
    "sendBackForReview": {
      "type": "Boolean"
    },
    "vlifeGrade": {
      "type": "ReportGrade"
    }
  },
  "VLifeReportFormCreateInput": {
    "approverId": {
      "type": "ID"
    },
    "channelId": {
      "type": "ID"
    },
    "channelName": {
      "type": "String"
    },
    "comment": {
      "type": "String"
    },
    "currentFirmwareRevision": {
      "type": "String"
    },
    "customerName": {
      "type": "String"
    },
    "fieldName": {
      "type": "String"
    },
    "insulationResistanceAtCommissioning": {
      "type": "Long"
    },
    "insulationResistanceAtEndOfReportingPeriod": {
      "type": "Long"
    },
    "lifetimeDataChart": {
      "type": "String"
    },
    "logs": {
      "type": "VLifeReportLogMessageCreateInput"
    },
    "monthCovered": {
      "type": "Date"
    },
    "mostRecentViperAssetInstallationDate": {
      "type": "Date"
    },
    "nextReportDue": {
      "type": "Date"
    },
    "overallIRIncrease": {
      "type": "Long"
    },
    "platformName": {
      "type": "String"
    },
    "projectCode": {
      "type": "String"
    },
    "reportPeriodEndDate": {
      "type": "Date"
    },
    "reportPeriodStartDate": {
      "type": "Date"
    },
    "reportingPeriodChart": {
      "type": "String"
    },
    "suggestedReviewerId": {
      "type": "ID"
    },
    "unitSerialNumbers": {
      "type": "String"
    },
    "vlifeFirstAppliedDate": {
      "type": "Date"
    },
    "vlifeLicenceExpiryDate": {
      "type": "Date"
    }
  },
  "VLifeReportFormPublishInput": {
    "comment": {
      "type": "String"
    },
    "sendBackForReview": {
      "type": "Boolean"
    }
  },
  "VLifeReportFormReviewInput": {
    "approverId": {
      "type": "ID"
    },
    "changeAlarmSettings": {
      "type": "Boolean"
    },
    "changeCommunicationSettings": {
      "type": "Boolean"
    },
    "comment": {
      "type": "String"
    },
    "decreaseSamplingFrequency": {
      "type": "Boolean"
    },
    "externalSummaryForClient": {
      "type": "String"
    },
    "furtherInvestigationRequired": {
      "type": "Boolean"
    },
    "increaseInFrequencyOfDataDownloads": {
      "type": "Boolean"
    },
    "increaseSamplingFrequency": {
      "type": "Boolean"
    },
    "logs": {
      "type": "VLifeReportLogMessageCreateInput"
    },
    "noChangeRequired": {
      "type": "Boolean"
    },
    "optimiseTheVLifeProcess": {
      "type": "Boolean"
    },
    "overallIRIncrease": {
      "type": "Long"
    },
    "sendForApproval": {
      "type": "Boolean"
    },
    "suggestedReviewerId": {
      "type": "ID"
    },
    "updateFirmwareToId": {
      "type": "ID"
    }
  },
  "VLifeReportLogMessageCreateInput": {
    "date": {
      "type": "DateTime"
    },
    "message": {
      "type": "String"
    }
  },
  "ViperAssetCreateInput": {
    "adminPassword": {
      "type": "String",
      "permission": "VIPER_ASSET_PASSWORD_WRITE"
    },
    "bomRevisionId": {
      "type": "ID"
    },
    "currentFirmwareRevisionId": {
      "type": "ID"
    },
    "customerId": {
      "type": "ID",
      "permission": "VIPER_ASSET_COMMERCIAL_WRITE"
    },
    "dateOfManufacture": {
      "type": "Date",
      "permission": "VIPER_ASSET_COMMERCIAL_WRITE"
    },
    "dateOfPurchase": {
      "type": "Date",
      "permission": "VIPER_ASSET_COMMERCIAL_WRITE"
    },
    "endClientId": {
      "type": "ID",
      "permission": "VIPER_ASSET_COMMERCIAL_WRITE"
    },
    "generalNotes": {
      "type": "String"
    },
    "ipAddress": {
      "type": "String"
    },
    "ipAssignmentMethodId": {
      "type": "ID"
    },
    "macAddress": {
      "type": "String"
    },
    "manufacturerId": {
      "type": "ID",
      "permission": "VIPER_ASSET_COMMERCIAL_WRITE"
    },
    "maxIrCap": {
      "type": "Long"
    },
    "partId": {
      "type": "ID"
    },
    "primaryCommsId": {
      "type": "ID"
    },
    "remoteAccess": {
      "type": "Boolean"
    },
    "rootRevisionPasswordId": {
      "type": "ID",
      "permission": "VIPER_ASSET_PASSWORD_WRITE"
    },
    "serialNumber": {
      "type": "String"
    }
  },
  "ViperAssetUpdateInput": {
    "adminPassword": {
      "type": "String",
      "permission": "VIPER_ASSET_PASSWORD_WRITE"
    },
    "bomRevisionId": {
      "type": "ID"
    },
    "customerId": {
      "type": "NonNullID",
      "permission": "VIPER_ASSET_COMMERCIAL_WRITE"
    },
    "dateOfManufacture": {
      "type": "Date",
      "permission": "VIPER_ASSET_COMMERCIAL_WRITE"
    },
    "dateOfPurchase": {
      "type": "Date",
      "permission": "VIPER_ASSET_COMMERCIAL_WRITE"
    },
    "endClientId": {
      "type": "ID",
      "permission": "VIPER_ASSET_COMMERCIAL_WRITE"
    },
    "generalNotes": {
      "type": "String"
    },
    "ipAddress": {
      "type": "String"
    },
    "ipAssignmentMethodId": {
      "type": "ID"
    },
    "macAddress": {
      "type": "String"
    },
    "manufacturerId": {
      "type": "ID",
      "permission": "VIPER_ASSET_COMMERCIAL_WRITE"
    },
    "maxIrCap": {
      "type": "Long"
    },
    "partId": {
      "type": "NonNullID"
    },
    "primaryCommsId": {
      "type": "ID"
    },
    "remoteAccess": {
      "type": "Boolean"
    },
    "rootRevisionPasswordId": {
      "type": "ID",
      "permission": "VIPER_ASSET_PASSWORD_WRITE"
    },
    "serialNumber": {
      "type": "NonNullString"
    }
  }
};
