import { MessagesLog } from '~/features/viper-asset/components/MessagesLog';
import { Box } from '@mui/material';
import { MaintenanceLog } from '~/features/viper-asset/components/MaintenanceLog';
import { FirmwareLog } from '~/features/viper-asset/components/FirmwareLog';
import { AuditLog } from '~/components/AuditLog/AuditLog';
import React from 'react';
import { useViperAsset } from '~/features/viper-asset/hooks/useViperAsset';

export const LogsTab = () => {
  const { id, viperAsset } = useViperAsset();
  return (
    <>
      <MessagesLog viperAsset={viperAsset} />
      <Box sx={{ mb: 4 }} />
      <MaintenanceLog viperAsset={viperAsset} />
      <Box sx={{ mb: 4 }} />
      <FirmwareLog viperAsset={viperAsset} />
      <Box sx={{ mb: 4 }} />
      <AuditLog entity='viperasset.ViperAsset' id={id} />
    </>
  );
};
