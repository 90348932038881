import { Actions } from '~/components/Actions/Actions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

export interface PositionedUploadVLogsButtonProps {
  isRenderedAtPageLevel: boolean;
  setUploadDialogOpen: (b: boolean) => void;
}

export const PositionedUploadVLogsButton = ({
  isRenderedAtPageLevel,
  setUploadDialogOpen,
}: PositionedUploadVLogsButtonProps) => {
  const { t } = useTranslation('v-log');

  if (isRenderedAtPageLevel) {
    return (
      <Actions
        actions={[
          {
            label: t('upload-v-log'),
            onClick: () => {
              setUploadDialogOpen(true);
            },
          },
        ]}
      />
    );
  } else {
    return (
      <Box display='flex' justifyContent='flex-end' alignItems='center' sx={{ mb: 2 }}>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => setUploadDialogOpen(true)}
        >
          {t('upload-v-log')}
        </Button>
      </Box>
    );
  }
};
