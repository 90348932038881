import { useMemo } from 'react';
import { EventType } from '~/graphql/generated/measurement/graphql';
import { useGetVLogFiles } from '~/features/v-logs/queries/v-log-measurement';
import { VLogsRowData } from '../pages/ListVLogsPage';
import { formatFileSize } from '../utils/formatFileSizes';

export const useVLogs = () => {
  const { data: vlogs, loading, error } = useGetVLogFiles();

  const data: VLogsRowData[] = useMemo(() => {
    return (vlogs?.vlogFiles ?? []).map((vlog) => {
      const lastVLogEvent = vlog.events[vlog.events.length - 1];
      const dateUploaded = vlog.events.find(
        (event) => event.eventType === EventType.Pending
      )?.timestamp;
      return {
        id: vlog.id,
        name: vlog.name,
        size: formatFileSize(vlog.sizeBytes),
        dateUploaded: dateUploaded,
        status: lastVLogEvent?.eventType,
        events: vlog.events,
      };
    });
  }, [vlogs?.vlogFiles]);

  return {
    loading,
    error,
    data,
  };
};
