import { Schema } from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import {
  GetChannelQuery,
  GetCreateChannelOptionsQuery,
  GetCreateViperServiceOptionsQuery,
} from '~/graphql/generated/asset/graphql';
import { getIdentificationEditSchema } from './identificationEditSchema';
import { getSystemInformationEditSchema } from './systemInformationEditSchema';
import { getElectricalEditSchema } from './electricalEditSchema';
import { getImdInterfaceConfigurationEditSchema } from './imdInterfaceConfigurationEditSchema';
import { getDataEditSchema } from './dataEditSchema';
import { getVLifeEditSchema } from '~/features/customer-asset/form-schemas/edit/v-life-licence/vLifeEditSchema';
import { buildPath, Path } from '~/routes/paths';
import { getSystemInformationViewSchema } from './systemInformationViewSchema';
import { SelectActionHandler } from '~/components/Form/formFields/select-action-handler';
import { getGeneralEditSchema } from './generalEditSchema';
import colors from '~/themes/colors';

interface GetCustomerAssetEditSchemaProps {
  t: TFunction;
  fields?: GetCreateChannelOptionsQuery['fields'];
  customers?: GetCreateChannelOptionsQuery['customers'];
  electricalTopologies?: GetCreateChannelOptionsQuery['electricalTopologies'];
  couplingTypes?: GetCreateChannelOptionsQuery['couplingTypes'];
  electricalDistributionTypes?: GetCreateChannelOptionsQuery['electricalDistributionTypes'];
  poweringDevices?: GetCreateChannelOptionsQuery['poweringDevices'];
  relayConfigurations?: GetCreateChannelOptionsQuery['relayConfigurations'];
  tripAlarmRelays?: GetCreateChannelOptionsQuery['tripAlarmRelays'];
  interfaceCards?: GetCreateChannelOptionsQuery['interfaceCards'];
  vlifeLicenceTypes?: GetCreateViperServiceOptionsQuery['vlifeLicenceTypes'];
  vlifeVersions?: GetCreateViperServiceOptionsQuery['vlifeVersions'];
  platforms?: GetCreateChannelOptionsQuery['platforms'];
  productionControlSystems?: GetCreateChannelOptionsQuery['productionControlSystems'];
  umbilicals?: GetCreateChannelOptionsQuery['umbilicals'];
  deactivationReasons?: GetCreateChannelOptionsQuery['deactivationReasons'];
  vLifeHistory?: Record<string, unknown>[];
  viperAssetHistory?: Record<string, unknown>[];
  vLifeHistoryDetails?: Record<string, unknown>[];
  channel?: GetChannelQuery['channel'];
  editing?: string;
  createLicenceComponent?: React.ReactNode;
  vLifeStatusComponent?: React.ReactNode;
  onAddField: SelectActionHandler;
  onAddPlatform: SelectActionHandler;
  onAddUmbilical: SelectActionHandler;
  onAddProductionControlSystem?: SelectActionHandler;
  onEditField: SelectActionHandler;
  onEditPlatform: SelectActionHandler;
  onEditUmbilical: SelectActionHandler;
  onEditProductionControlSystem?: SelectActionHandler;
  onDeleteUpcomingLicence?: (id: string) => void;
}

export function getCustomerAssetEditSchema({
  t,
  fields,
  customers,
  couplingTypes,
  poweringDevices,
  relayConfigurations,
  tripAlarmRelays,
  interfaceCards,
  vlifeLicenceTypes,
  vlifeVersions,
  platforms,
  productionControlSystems,
  umbilicals,
  deactivationReasons,
  vLifeHistory = [],
  viperAssetHistory = [],
  vLifeHistoryDetails = [],
  channel,
  editing,
  createLicenceComponent,
  vLifeStatusComponent,
  onAddField,
  onAddPlatform,
  onAddUmbilical,
  onAddProductionControlSystem,
  onEditField,
  onEditPlatform,
  onEditUmbilical,
  onEditProductionControlSystem,
  onDeleteUpcomingLicence,
}: GetCustomerAssetEditSchemaProps): Schema {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-customer-wizard',
        fields: [
          {
            name: 'set-up-v-life-group',
            title: 'set-up-v-life-group', // not displayed
            component: 'group',
            highlightColor: colors.lightblue[100],
            when: () => !channel?.mostRecentVLifeConfig && !channel?.upcomingVLifeConfig,
            fields: [
              {
                name: 'set-up-v-life',
                title: 'set-up-v-life', // not displayed
                nextStep: 'identification',
                component: 'custom',
                customComponent: createLicenceComponent,
              },
            ],
          },
          {
            name: 'identification',
            title: t('customer-asset-forms::identification'),
            nextStep: 'vLife',
            fields: getIdentificationEditSchema({
              t,
              fields,
              customers,
              channel,
              editing: editing === 'identification',
              onAddField,
              onEditField,
            }).fields,
          },
          {
            name: 'systemInformation',
            title: t('customer-asset-forms::system-information'),
            nextStep: 'vLife',
            fields: editing
              ? getSystemInformationEditSchema({
                  t,
                  channel,
                  platforms,
                  productionControlSystems,
                  umbilicals,
                  onAddPlatform,
                  onAddUmbilical,
                  onAddProductionControlSystem,
                  onEditPlatform,
                  onEditUmbilical,
                  onEditProductionControlSystem,
                }).fields
              : getSystemInformationViewSchema({
                  t,
                  channel,
                }).fields,
          },
          {
            name: 'vLife',
            title: t('customer-asset-forms::vLife'),
            nextStep: 'viperAssets',
            component: 'group',
            when: () =>
              channel?.mostRecentVLifeConfig ||
              channel?.upcomingVLifeConfig ||
              (channel?.historicalVLifeConfigs && channel?.historicalVLifeConfigs.length > 0),
            highlightColor:
              !channel?.mostRecentVLifeConfig || channel?.mostRecentVLifeConfig?.isExpired
                ? colors.grey[400]
                : colors.lightblue[100],
            fields: [
              {
                name: 'v-life-status',
                title: 'v-life-status', // not displayed
                nextStep: 'identification',
                component: 'custom',
                customComponent: vLifeStatusComponent,
              },
              {
                name: 'upcomingVLifeConfig',
                title: t('customer-asset-forms::upcoming-v-life-licence'),
                component: 'panel',
                when: () => channel?.upcomingVLifeConfig,
                customButton: {
                  onCustomButtonClick: () => {
                    if (channel?.upcomingVLifeConfig?.id) {
                      onDeleteUpcomingLicence?.(channel?.upcomingVLifeConfig?.id);
                    }
                  },
                  color: 'error',
                  variant: 'contained',
                  label: t('viper::delete'),
                  disabled: !!editing,
                },
                fields: getVLifeEditSchema({
                  t,
                  vLifeStatus: channel?.vLifeTimeline?.currentStatus,
                  vlifeConfig: channel?.upcomingVLifeConfig,
                  vlifeLicenceTypes,
                  vlifeVersions,
                  deactivationReasons,
                  isUpcoming: true,
                }).fields,
              },
              {
                name: 'mostRecentVLifeConfig',
                title: t('customer-asset-forms::v-life-licence'),
                component: 'panel',
                when: () => channel?.mostRecentVLifeConfig,
                customButton: !channel?.upcomingVLifeConfig &&
                  !channel?.mostRecentVLifeConfig?.isExpired && {
                    to: buildPath(Path.V_LIFE_LICENCE_CREATE_PATH, { id: channel?.id }),
                    color: 'secondary',
                    variant: 'contained',
                    label: t('customer-asset-forms::renew-licence'),
                  },
                fields: getVLifeEditSchema({
                  t,
                  vLifeStatus: channel?.vLifeTimeline?.currentStatus,
                  vlifeConfig: channel?.mostRecentVLifeConfig,
                  vlifeLicenceTypes,
                  vlifeVersions,
                  deactivationReasons,
                  editing: editing === 'mostRecentVLifeConfig',
                }).fields,
              },
              {
                name: 'vLifeHistory',
                title: t('customer-asset-forms::v-life-history'),
                component: 'list',
                data: vLifeHistory,
                nestedData: vLifeHistoryDetails,
                omittedKeys: ['_id'],
              },
            ],
          },
          {
            name: 'viperAssets',
            title: t('customer-asset-forms::viper-assets'),
            nextStep: 'electrical',
            component: 'group',
            highlighted: false,
            fields: [
              {
                name: 'viperAssetHistory',
                title: t('customer-asset-forms::viper-assets'),
                showTitle: true,
                component: 'list',
                data: viperAssetHistory,
                omittedKeys: ['_id'],
                emptyText: t('customer-asset-forms::no-asset-associated'),
              },
            ],
          },
          {
            name: 'electrical',
            title: t('customer-asset-forms::electrical'),
            nextStep: 'imdInterfaceConfiguration',
            fields: getElectricalEditSchema({
              t,
              couplingTypes,
              channel,
              editing: editing === 'electrical',
            }).fields,
          },
          {
            name: 'imdInterfaceConfiguration',
            title: t('customer-asset-forms::imd-interface-configuration'),
            fields: getImdInterfaceConfigurationEditSchema({
              t,
              poweringDevices,
              relayConfigurations,
              tripAlarmRelays,
              interfaceCards,
              channel,
              editing: editing === 'imdInterfaceConfiguration',
            }).fields,
          },
          {
            name: 'data',
            title: t('customer-asset-forms::data'),
            fields: getDataEditSchema({
              t,
              channel,
              editing: editing === 'data',
            }).fields,
          },
          {
            name: 'general',
            title: t('customer-asset-forms::general'),
            fields: getGeneralEditSchema({
              t,
              channel,
              editing: editing === 'general',
            }).fields,
          },
        ],
      },
    ],
  };
}
