import React from 'react';
import { Card } from '~/components/Card/Card';
import { Typography } from '@mui/material';
import { FooterActions } from '~/components/Form/FooterActions/FooterActions';
import { CustomButtonProps } from '~/components/Form/CustomButtonProps';

interface ViewPaneProps {
  editable?: boolean;
  onSaveClicked?: () => void;
  onCancelClicked?: () => void;
  onEditClicked?: () => void;
  title?: React.ReactNode;
  editingThisSection: boolean;
  editingAnotherSection: boolean;
  children: React.ReactNode;
  saving: boolean;
  saveButtonLabel?: string;
  disableSave?: boolean;
  customButton?: CustomButtonProps;
}

export const ViewPane = ({
  editable = true,
  onSaveClicked,
  onCancelClicked,
  onEditClicked,
  editingThisSection,
  editingAnotherSection,
  title,
  children,
  saving,
  saveButtonLabel,
  disableSave,
  customButton,
}: ViewPaneProps) => {
  return (
    <Card
      aria-label={title}
      footerContent={
        !editable ? null : (
          <FooterActions
            editingThisSection={editingThisSection}
            editingAnotherSection={editingAnotherSection}
            onCancelClicked={onCancelClicked}
            onEditClicked={onEditClicked}
            onSaveClicked={onSaveClicked}
            saving={saving}
            saveButtonLabel={saveButtonLabel}
            disableSave={disableSave}
            customButton={customButton}
          />
        )
      }
    >
      <Typography variant='h3' component='h3' sx={{ ml: 0.75, mb: 1.25 }}>
        {title}
      </Typography>
      {children}
    </Card>
  );
};
