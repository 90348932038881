import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Path, buildPath } from '~/routes/paths';
import { Chip } from '@mui/material';
import colors from '~/themes/colors';

interface SetUpVLifePillProps {
  id?: string;
}

export const SetUpVLifePill = ({ id }: SetUpVLifePillProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('customer-assets');

  return (
    <Chip
      size='small'
      label={t('set-up-now')}
      color='secondary'
      sx={{ backgroundColor: colors.yellow[500] }}
      onClick={(event: React.MouseEvent<Element, MouseEvent> | undefined) => {
        event?.stopPropagation();
        event?.preventDefault();
        navigate(buildPath(Path.V_LIFE_LICENCE_CREATE_PATH, { id }));
      }}
    />
  );
};
