import { Field, Schema } from '@data-driven-forms/react-form-renderer';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DataCardStack } from '~/components/Form/DataCardStack/DataCardStack';
import {
  getConfigDateFormValidator,
  getDisableDateWithinDatesFormValidator,
  getEndDateAfterStartFormValidator,
  getEndDateBeforeNextFormValidator,
  getStartDateFormValidator,
} from '~/components/Form/utils/date-validators';
import { mapValuesToVariables } from '~/components/Form/utils/map-variables';
import { SaveError, withErrorHandling } from '~/components/Form/SaveError/SaveError';
import {
  useDeleteVLifeConfig,
  useGetChannelNamesByField,
  useUpdateChannel,
} from '~/features/customer-asset/queries/customer-asset';
import { buildPath, Path } from '~/routes/paths';
import {
  ChannelUpdateInput,
  GetChannelQuery,
  NonNullDate,
  NonNullId,
  NonNullString,
} from '~/graphql/generated/asset/graphql';
import { CreateFieldDialog } from '~/features/customer-asset/components/dialogs/CreateFieldDialog';
import { UpdateFieldDialog } from '~/features/customer-asset/components/dialogs/UpdateFieldDialog';
import { useCustomerAssetSchema } from './useCustomerAssetSchema';
import { CreatePlatformDialog } from '~/features/customer-asset/components/dialogs/CreatePlatformDialog';
import { CreateUmbilicalDialog } from '~/features/customer-asset/components/dialogs/CreateUmbilicalDialog';
import { CreatePcsDialog } from '~/features/customer-asset/components/dialogs/CreatePcsDialog';
import { UpdatePlatformDialog } from '~/features/customer-asset/components/dialogs/UpdatePlatformDialog';
import { UpdateUmbilicalDialog } from '~/features/customer-asset/components/dialogs/UpdateUmbilicalDialog';
import { UpdatePcsDialog } from '~/features/customer-asset/components/dialogs/UpdatePcsDialog';
import { Dialog } from '~/components/Dialog/Dialog';
import { Toast } from '~/components/Toast/Toast';
import { DisableVLifeConfigDialog } from '~/features/customer-asset/components/dialogs/DisableVLifeConfigDialog';
import { createChannelNameAndFieldFormValidator } from '~/features/customer-asset/utils/createChannelNameAndFieldFormValidator';
import { getChannelDisplayName } from '~/utils/getChannelDisplayName';
import { useSetTitle } from '~/components/TitleProvider/TitleProvider';
import { CreateVLifeLicencePane } from '~/features/customer-asset/components/CreateVLifeLicencePane';

interface DeleteVlifeConfigDialogProps {
  channelId: string;
  deleteId: string | undefined;
  setDeleteId: (v: string | undefined) => void;
}

const DeleteVlifeConfigDialog: React.FC<DeleteVlifeConfigDialogProps> = ({
  channelId,
  deleteId,
  setDeleteId,
}) => {
  const { t } = useTranslation('customer-asset-forms');

  const [deleteVlife, deleteVlifeError] = withErrorHandling(useDeleteVLifeConfig(channelId));

  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleConfirm = async () => {
    if (!deleteId) {
      return;
    }
    setLoading(true);
    try {
      await deleteVlife({ variables: { id: deleteId } });
      setDeleted(true);
    } finally {
      setDeleteId(undefined);
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={!!deleteId}
        title={t('delete-vlife-title')}
        onClose={() => {
          setDeleteId(undefined);
        }}
        confirmText={t('viper::delete')}
        cancelText={t('viper::cancel')}
        confirmColor='error'
        onConfirm={handleConfirm}
        loading={loading}
      />
      <SaveError errorState={deleteVlifeError}>{t('error-deleting-vlife')}</SaveError>

      <Toast
        severity='success'
        open={deleted}
        onClose={() => {
          setDeleted(false);
        }}
        autoHideDuration={5000}
      >
        {t('vlife-deleted-successfully')}
      </Toast>
    </>
  );
};

/**
 * Gets a date to use in validation. While editing a licence, its
 * start date will not be allowed to be earlier than or equal to this date.
 * If we're editing the current licence, this date is the end date of
 * the most recent historical licence. If we're editing the upcoming licence,
 * this date is the end date of the current licence if there is one, otherwise
 * it's the end date of the most recent historical licence.
 */
export function getPreviousDate(
  channel: GetChannelQuery | undefined,
  editing: string | undefined
) {
  const historicalEndDate =
    channel?.channel?.historicalVLifeConfigs?.[0]?.expiryDateCommToCustomer;
  return editing === 'mostRecentVLifeConfig'
    ? historicalEndDate
    : channel?.channel?.mostRecentVLifeConfig?.expiryDateCommToCustomer || historicalEndDate;
}

/**
 * Gets a date to use in validation. While editing a licence, its
 * end date will not be allowed to be equal to or later than this date.
 * If we're editing the current licence, this date is the start date of the
 * upcoming licence, if there is one. If we're editing the upcoming licence
 * there is no limit to the end date.
 */
export function getNextDate(
  channel: GetChannelQuery | undefined,
  editing: string | undefined
) {
  return editing === 'mostRecentVLifeConfig'
    ? channel?.channel?.upcomingVLifeConfig?.startDate
    : undefined;
}

export const CustomerAssetOverview: React.FC<{ channelId: string }> = ({ channelId }) => {
  const { t } = useTranslation('customer-asset-forms');
  const navigate = useNavigate();

  const [editing, setEditing] = useState<string | undefined>();
  const [saving, setSaving] = useState(false);
  const [updateChannel, errorState] = withErrorHandling(useUpdateChannel());

  const { channelNamesByField, loading: loadingChannelNames } = useGetChannelNamesByField();

  const {
    channel,
    channelSchema,
    createDialogOpen,
    setCreateDialogOpen,
    setUpdateDialogOpen,
    platform,
    updateDialogOpen,
    loading: loadingChannels,
    deleteId,
    setDeleteId,
  } = useCustomerAssetSchema(
    channelId,
    <CreateVLifeLicencePane
      onClick={() => {
        navigate(buildPath(Path.V_LIFE_LICENCE_CREATE_PATH, { id: channelId }));
      }}
    />,
    editing
  );

  useSetTitle(getChannelDisplayName(channel?.channel) ?? '');

  // all fields in the schema, not just in one section
  const allFields: Field[] = useMemo(
    () => channelSchema.fields[0].fields.flatMap((field: Field) => field.fields),
    [channelSchema.fields]
  );

  const validate = useMemo(() => {
    const validateChannelNameAndField = createChannelNameAndFieldFormValidator(
      channelNamesByField,
      t('channel-name-by-field-message'),
      // allow user to keep using the current field/name combination
      {
        name: allFields.find((field) => field?.name === 'name')?.initialValue,
        fieldId: allFields.find((field) => field?.name === 'fieldId')?.initialValue?.id,
      }
    );

    const previousDate = getPreviousDate(channel, editing);
    const nextDate = getNextDate(channel, editing);

    const startDateValidator = getStartDateFormValidator(
      previousDate ? DateTime.fromISO(previousDate) : undefined,
      t('v-life-setup::date-validation/start-date-before-previous')
    );
    const endDateAfterStartValidator = getEndDateAfterStartFormValidator(
      t('v-life-setup::date-validation/end-date-before-start')
    );
    const endDateBeforeNextValidator = getEndDateBeforeNextFormValidator(
      nextDate ? DateTime.fromISO(nextDate) : undefined,
      t('v-life-setup::date-validation/end-date-after-next')
    );
    const configDateValidator = getConfigDateFormValidator(
      t('v-life-setup::date-validation/config-date-before-end')
    );
    return (values: Record<string, unknown>) => {
      return {
        ...startDateValidator(values),
        ...endDateAfterStartValidator(values),
        ...endDateBeforeNextValidator(values),
        ...configDateValidator(values),
        ...validateChannelNameAndField(values),
      };
    };
  }, [channelNamesByField, t, allFields, channel, editing]);

  const validateDisable = useMemo(() => {
    const startDate = channel?.channel?.mostRecentVLifeConfig?.startDate;
    const endDate = channel?.channel?.mostRecentVLifeConfig?.expiryDateCommToCustomer;

    const disableDateValidator = getDisableDateWithinDatesFormValidator(
      startDate ? DateTime.fromISO(startDate) : undefined,
      endDate ? DateTime.fromISO(endDate) : undefined,
      t('v-life-setup::date-validation/within-previous-and-next')
    );
    return (values: Record<string, unknown>) => {
      return {
        ...disableDateValidator(values),
      };
    };
  }, [t, channel]);

  const handleSave = useCallback(
    async (section: Schema & { name: string }, values: Record<string, unknown>) => {
      const variables = mapValuesToVariables(section.fields, values);
      let channelInput: ChannelUpdateInput = {
        id: channelId,
      };
      setSaving(true);
      try {
        if (
          section.name === 'mostRecentVLifeConfig' ||
          section.name === 'upcomingVLifeConfig'
        ) {
          channelInput[section.name] = {
            expiryDateCommToCustomer: variables?.expiryDateCommToCustomer as NonNullDate,
            expiryDateInConfig: variables?.expiryDateInConfig as NonNullDate,
            startDate: variables?.startDate as NonNullDate,
            vlifeVersionId: variables?.vlifeVersionId as NonNullId,
            notes: variables?.notes as string,
            vlifeLicence: {
              purchaseOrderNumber: variables?.purchaseOrderNumber as NonNullString,
              salesOrderNumber: variables?.salesOrderNumber as NonNullString,
              starjarProjectNumber: variables?.starjarProjectNumber as NonNullString,
              vlifeLicenceTypeId: variables?.vlifeLicenceTypeId as NonNullId,
            },
            flaggedForDecommission: variables?.flaggedForDecommission as boolean,
            flaggedForDecommissionDate: variables?.flaggedForDecommission
              ? (variables?.flaggedForDecommissionDate as string)
              : null,
          };
        } else {
          channelInput = {
            ...channelInput,
            ...variables,
          };
        }
        const result = await updateChannel({
          variables: {
            channelInput,
          },
        });
        if (!result.errors) {
          setEditing?.(undefined);
          setSaving(false);
        }
      } catch (e) {
        setSaving(false);
      }
    },
    [channelId, updateChannel]
  );

  return (
    <>
      <DataCardStack
        data={channelSchema}
        loading={loadingChannelNames || loadingChannels}
        saving={saving}
        translationKey='customer-asset'
        editing={editing}
        setEditing={setEditing}
        onCancel={() => setEditing(undefined)}
        onSave={handleSave}
        validate={validate}
      />
      <SaveError errorState={errorState}>{t('error-saving-customer-asset')}</SaveError>

      <CreateFieldDialog
        open={createDialogOpen === 'field'}
        onClose={() => setCreateDialogOpen(undefined)}
      />

      <CreatePlatformDialog
        open={createDialogOpen === 'platform'}
        onClose={() => setCreateDialogOpen(undefined)}
      />

      <CreateUmbilicalDialog
        open={createDialogOpen === 'umbilical'}
        onClose={() => setCreateDialogOpen(undefined)}
        platform={platform}
      />

      <CreatePcsDialog
        open={createDialogOpen === 'pcs'}
        onClose={() => setCreateDialogOpen(undefined)}
        platform={platform}
      />

      <UpdateFieldDialog
        editId={updateDialogOpen?.type === 'field' ? updateDialogOpen.id : undefined}
        onClose={() => setUpdateDialogOpen(undefined)}
      />

      <UpdatePlatformDialog
        editId={updateDialogOpen?.type === 'platform' ? updateDialogOpen.id : undefined}
        onClose={() => setUpdateDialogOpen(undefined)}
      />

      <UpdateUmbilicalDialog
        editId={updateDialogOpen?.type === 'umbilical' ? updateDialogOpen.id : undefined}
        onClose={() => setUpdateDialogOpen(undefined)}
      />

      <UpdatePcsDialog
        editId={updateDialogOpen?.type === 'pcs' ? updateDialogOpen.id : undefined}
        onClose={() => setUpdateDialogOpen(undefined)}
      />

      {channel?.channel && (
        <DisableVLifeConfigDialog
          editId={updateDialogOpen?.type === 'disable-vlife' ? updateDialogOpen.id : undefined}
          onClose={() => setUpdateDialogOpen(undefined)}
          channel={channel.channel}
          validate={validateDisable}
        />
      )}

      <DeleteVlifeConfigDialog
        channelId={channelId}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
      />
    </>
  );
};
