import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportGrade, ReportSource } from '~/graphql/generated/asset/graphql';
import { joinErrorStates, withErrorHandling } from '~/components/Form/SaveError/SaveError';
import { RowDataType } from '~/components/Table/Table';
import { useUpload } from '~/components/Upload/useUpload';
import { formatDate } from '~/utils/date-utils';
import { env } from '~/config/environmentVariables';
import {
  useCreateVLifeReport,
  useGetVLifeReportDownloadToken,
  useGetVLifeReports,
} from '~/features/v-life-reports/queries/v-life-reports';

export function useVLifeReports(channelId: string) {
  const { t } = useTranslation('customer-asset');
  const [getDownloadToken] = useGetVLifeReportDownloadToken();
  const { data, loading } = useGetVLifeReports(channelId);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const vLifeReports = useMemo(
    () =>
      data?.vlifeReports?.map((report) => {
        const {
          id,
          name,
          documentNumber,
          revision,
          startDate,
          endDate,
          periodCovered,
          source,
          channel: { customer, name: channelName, field },
        } = report;
        return {
          _id: id,
          _fileName: name,
          name: `${customer?.name} ${field?.name} ${channelName} ${formatDate(periodCovered, {
            month: 'long',
            year: 'numeric',
          })}`,
          revision: revision
            ? { label: `${revision}`.padStart(2, '0'), value: revision }
            : null,
          documentNumber,
          periodCovered: {
            label: `${formatDate(startDate)} - ${formatDate(endDate)}`,
            value: startDate,
          },
          source: source ? t(source) : undefined,
        };
      }) ?? [],
    [data?.vlifeReports, t]
  );

  const handleActionClick = useCallback(
    async (_menuItemId: string, _rowIndex: number, row?: RowDataType) => {
      const { _id } = row ?? {};
      const id = _id as string;

      try {
        const result = await getDownloadToken({ variables: { id } });
        location.href = getVLifeReportDownloadEndpoint(
          id,
          result.data?.vlifeReportDownloadToken ?? ''
        );
      } catch (e) {
        console.error(e);
      }
    },
    [getDownloadToken]
  );

  const vLifeReportDetails = useMemo(
    () =>
      data?.vlifeReports?.map((report) => {
        const { id, dateReleased, vlifeGrade } = report;
        return {
          _id: id,
          [t('dateReleased')]: formatDate(dateReleased),
          [t('vlifeGrade')]: vlifeGrade,
        };
      }) ?? [],
    [data?.vlifeReports, t]
  );

  const { uploadFile } = useUpload();
  const [createVLifeReport, createReportError] = withErrorHandling(useCreateVLifeReport());
  const [uploadFileError, setUploadFileError] = useState(false);
  const uploadReportError = joinErrorStates(createReportError, {
    error: uploadFileError,
    reset: () => setUploadFileError(false),
  });

  const handleUpload = useCallback(
    (files: File[], formValues?: Record<string, unknown> | undefined) => {
      setUploaded(false);
      uploadReportError.reset();
      uploadFile(
        `${env.REACT_APP_ASSET_API_ENDPOINT}/vlifereport`,
        files[0],
        async (id) => {
          if (id < 0) {
            // warning triangle will be displayed in upload progress widget
            setUploadFileError(true);
            return;
          }
          const result = await createVLifeReport({
            variables: {
              vlifeReportInput: {
                channelId,
                dateReleased: formValues?.dateReleased as string,
                documentNumber: formValues?.documentNumber as string,
                endDate: formValues?.endDate as string,
                name: files[0].name,
                periodCovered: formValues?.periodCovered as string,
                revision: formValues?.revision as number,
                source: ReportSource.Uploaded,
                startDate: formValues?.startDate as string,
                vlifeGrade: formValues?.vlifeGrade as ReportGrade,
                vlifeReportBlobId: String(id),
              },
            },
          });
          if (!result.errors) {
            setUploaded(true);
          }
        },
        () => {
          // do nothing special when upload starts
        }
      );
      setShowUploadDialog(false);
    },
    [uploadReportError, uploadFile, createVLifeReport, channelId]
  );

  return {
    vLifeReports,
    vLifeReportDetails,
    handleActionClick,
    showUploadDialog,
    setShowUploadDialog,
    handleUpload,
    loading,
    uploaded,
    setUploaded,
    uploadReportError,
  };
}

export function getVLifeReportDownloadEndpoint(id: string, token: string) {
  return `${env.REACT_APP_ASSET_API_ENDPOINT}/vlifereport/${id}?token=${encodeURIComponent(
    token
  )}`;
}
