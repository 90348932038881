import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  useCreateChannel,
  useGetChannelNamesByField,
  useGetCreateChannelOptions,
} from '~/features/customer-asset/queries/customer-asset';
import { WizardLoadingSkeleton } from '~/components/LoadingSkeleton/LoadingSkeleton';
import { useNavigate } from 'react-router-dom';
import { CreateWizard } from '~/components/Form/Wizard/CreateWizard';
import { Platform } from '~/graphql/generated/asset/graphql';
import { buildPath, Path } from '~/routes/paths';
import { SaveError, withErrorHandling } from '~/components/Form/SaveError/SaveError';
import { SelectActionHandler } from '~/components/Form/formFields/select-action-handler';
import { CreateFieldDialog } from '~/features/customer-asset/components/dialogs/CreateFieldDialog';
import { CreatePlatformDialog } from '~/features/customer-asset/components/dialogs/CreatePlatformDialog';
import { CreateUmbilicalDialog } from '~/features/customer-asset/components/dialogs/CreateUmbilicalDialog';
import { CreatePcsDialog } from '~/features/customer-asset/components/dialogs/CreatePcsDialog';
import { createChannelNameAndFieldFormValidator } from '~/features/customer-asset/utils/createChannelNameAndFieldFormValidator';
import { getCustomerAssetCreateSchema } from '~/features/customer-asset/form-schemas/create/customer-asset/customerAssetCreateSchema';

export const CreateCustomerAsset: React.FC = () => {
  const { t } = useTranslation('customer-asset-forms');
  const [createChannel, errorState, { loading: saving }] = withErrorHandling(
    useCreateChannel()
  );
  const navigate = useNavigate();

  const { data: createOptions, loading } = useGetCreateChannelOptions();
  const { channelNamesByField, loading: channelsLoading } = useGetChannelNamesByField();
  const [createDialogOpen, setCreateDialogOpen] = useState<string | undefined>(undefined);
  const [platform, setPlatform] = useState<Platform | undefined>();

  const onAddField = useCallback(() => {
    setCreateDialogOpen('field');
  }, []);

  const onAddPlatform = useCallback(() => {
    setCreateDialogOpen('platform');
  }, []);

  const onAddUmbilical = useCallback<SelectActionHandler>(
    (_event, value, values) => {
      setPlatform(
        createOptions?.platforms?.find((platform) => platform.id === values['platformId'])
      );
      setCreateDialogOpen('umbilical');
    },
    [createOptions?.platforms]
  );

  const onAddProductionControlSystem = useCallback<SelectActionHandler>(
    (_event, value, values) => {
      setPlatform(
        createOptions?.platforms?.find((platform) => platform.id === values['platformId'])
      );
      setCreateDialogOpen('pcs');
    },
    [createOptions?.platforms]
  );

  if (loading || channelsLoading) {
    return <WizardLoadingSkeleton />;
  }

  if (!createOptions || !channelNamesByField) {
    return <p>{t('no-data')}</p>;
  }

  const createWizardSchema = getCustomerAssetCreateSchema({
    t,
    createOptions,
    onAddField,
    onAddPlatform,
    onAddUmbilical,
    onAddProductionControlSystem,
  });

  return (
    <>
      <CreateWizard
        validate={createChannelNameAndFieldFormValidator(
          channelNamesByField,
          t('channel-name-by-field-message')
        )}
        schema={createWizardSchema}
        saving={saving}
        onSubmit={async ({
          name,
          fieldId,
          customerId,
          operatorId,
          viperProjectNumber,

          operatingVoltage,
          referenceIR,
          lifetimeStartDate,
          couplingTypeId,

          alarm1Value,
          alarm2Value,
          tripAlarmRelayId,
          relayConfigurationId,
          couplerUsed,
          interfaceCardId,
          poweringDeviceId,
          negativeImpactOnRebootNotes,

          platformId,
          umbilicalId,
          productionControlSystemId,
        }: Record<string, unknown>) => {
          const result = await createChannel({
            variables: {
              channelInput: {
                name: name as string,
                fieldId: fieldId as string,
                customerId: customerId as string,
                operatorId: operatorId as string,
                viperProjectNumber: viperProjectNumber as string,

                operatingVoltage: operatingVoltage as number,
                referenceIR: referenceIR as number,
                lifetimeStartDate: lifetimeStartDate as string,
                couplingTypeId: couplingTypeId as string,

                alarm1Value: alarm1Value as number,
                alarm2Value: alarm2Value as number,
                tripAlarmRelayId: tripAlarmRelayId as string,
                relayConfigurationId: relayConfigurationId as string,
                couplerUsed: couplerUsed as string,
                interfaceCardId: interfaceCardId as string,
                poweringDeviceId: poweringDeviceId as string,
                negativeImpactOnRebootNotes: negativeImpactOnRebootNotes as string,

                platformId: platformId as string,
                umbilicalId: umbilicalId as string,
                productionControlSystemId: productionControlSystemId as string,
              },
            },
          });
          if (!result.errors) {
            const id = result.data?.createChannel?.id;
            navigate(buildPath(Path.CUSTOMER_ASSET_GRAPH_PATH, { id }));
          }
        }}
        onCancel={() => {
          navigate(Path.CUSTOMER_ASSET_LIST_PATH);
        }}
      />
      <SaveError errorState={errorState}>{t('error-creating-channel')}</SaveError>

      <CreateFieldDialog
        open={createDialogOpen === 'field'}
        onClose={() => setCreateDialogOpen(undefined)}
      />

      <CreatePlatformDialog
        open={createDialogOpen === 'platform'}
        onClose={() => setCreateDialogOpen(undefined)}
      />

      <CreateUmbilicalDialog
        open={createDialogOpen === 'umbilical'}
        onClose={() => setCreateDialogOpen(undefined)}
        platform={platform}
      />

      <CreatePcsDialog
        open={createDialogOpen === 'pcs'}
        onClose={() => setCreateDialogOpen(undefined)}
        platform={platform}
      />
    </>
  );
};
