import { getToken, protectedResources } from '../common/msal';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { withScalars } from 'apollo-link-scalars';
import { ApolloLink } from '@apollo/client/core';
import introspectionResult from '~/graphql/generated/asset/graphql.schema.json';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import { typesMap } from './graphqlTypesMap';
import { env } from '~/config/environmentVariables';

const httpLink = createHttpLink({
  uri: `${env.REACT_APP_ASSET_API_ENDPOINT}/graphql`,
});

const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery);

const scalarsLink = withScalars({
  schema,
  typesMap,
});

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getToken([...protectedResources.apis.scopes.asset]);
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const link = ApolloLink.from([authLink, scalarsLink, httpLink]);
const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  },
});
