export const getChannelDisplayName = (
  channel:
    | {
        name: string;
        customer?: {
          name: string;
        } | null;
        field?: {
          name: string;
        } | null;
      }
    | null
    | undefined
) => {
  if (!channel) {
    return undefined;
  }
  return `${channel.customer?.name ?? '-'} : ${channel.field?.name ?? '-'} : ${channel.name}`;
};
