import { useTranslation } from 'react-i18next';
import { useGetChannel } from '~/features/customer-asset/queries/customer-asset';
import { useMemo } from 'react';
import { buildPath, Path } from '~/routes/paths';
import { WarningLink } from '~/components/WarningLink/WarningLink';
import { WarningListResponse } from '~/features/v-life-reports/hooks/types';

export const useGetCustomerAssetWarnings = (
  channelId: string | undefined
): WarningListResponse => {
  const { t } = useTranslation('warnings');
  const { data, loading } = useGetChannel(channelId);

  const warnings = useMemo<WarningLink[]>((): WarningLink[] => {
    if (!channelId || loading || !data) {
      return [];
    }

    const missingCustomerAssetInformation =
      !data.channel?.platform || !data.channel.referenceIR || !data.channel.lifetimeStartDate;

    if (missingCustomerAssetInformation) {
      return [
        {
          id: channelId,
          warningMessage: t('missing-values-in-customer-asset'),
          link: {
            text: t('link-to-customer-asset'),
            url: buildPath(Path.CUSTOMER_ASSET_GRAPH_PATH, { id: channelId }),
          },
        },
      ];
    }

    return [];
  }, [channelId, loading, data, t]);

  return {
    warnings,
    loading,
  };
};
