import { InputPermissions } from '~/graphql/generated/asset/input-auth';
import { useRBAC } from '~/authorization/utils/useRBAC';
import { RBACPermission } from '~/authorization/permissions';
// Required to test recursive calls
import * as currentModule from './guardVariable';

/**
 * @param variable A variable that needs to be guarded.
 * @param typeName The type of the variable we are guarding.
 * @param userHasPermission
 * @param inputPermissions
 */
export const guardVariable = (
  variable: unknown,
  typeName: string,
  userHasPermission: ReturnType<typeof useRBAC>['userHasPermission'],
  inputPermissions: InputPermissions
) => {
  const containingType = inputPermissions[typeName];
  if (!containingType) {
    // If we don't know about the type then it's not an input type
    // and it must be a scalar. We'll have already checked its
    // permissions before calling guardVariable, so we can just
    // return it without any more processing.
    return variable;
  }

  if (variable === undefined || variable === null) {
    return variable;
  }

  // If the variable is an array then we call `guardVariable` on
  // each of the values to guard them.
  if (Array.isArray(variable)) {
    const newArray: unknown[] = [];
    variable.forEach((arrayItem) => {
      const guardedValue = currentModule.guardVariable(
        arrayItem,
        typeName,
        userHasPermission,
        inputPermissions
      );
      // If we have a value, add it to the new array
      if (guardedValue !== undefined) {
        newArray.push(guardedValue);
      }
    });
    return newArray;
  }

  // We have a type from `schemaPermission`, and it's not an array,
  // so it must be a record now. So we'll guard each item in the record.
  const newVariables: Record<string, unknown> = {};
  Object.entries(variable as Record<string, unknown>).forEach(([name, value]) => {
    // Get the expected type of the field within this record
    const fieldType = containingType[name];
    // If we have permission to send this variable, process it
    if (
      fieldType &&
      (!fieldType.permission || userHasPermission([fieldType.permission as RBACPermission]))
    ) {
      const guardedValue = currentModule.guardVariable(
        value,
        fieldType.type,
        userHasPermission,
        inputPermissions
      );
      // If we have a value, add it to the new variables
      if (guardedValue !== undefined) {
        newVariables[name] = guardedValue;
      }
    }
  });

  return newVariables;
};
