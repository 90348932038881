import { UserPermissionsDocument } from '~/graphql/generated/asset/graphql';
import { useQuery } from '@apollo/client';

interface UseGetUserPermissionsProps {
  skip: boolean;
}

export const useGetUserPermissions = ({ skip }: UseGetUserPermissionsProps) => {
  return useQuery(UserPermissionsDocument, {
    fetchPolicy: 'cache-first',
    skip,
  });
};
