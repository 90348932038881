import { useQuery } from '@apollo/client';
import { useGuardedMutation } from '~/graphql/authorization/useGuardedMutation';
import {
  CreateContactDocument,
  GetContactDocument,
  GetCreateContactOptionsDocument,
  GetPeopleDocument,
  UpdateContactDocument,
} from '~/graphql/generated/asset/graphql';

export function useGetContacts() {
  return useQuery(GetPeopleDocument);
}

export function useGetCreateContactOptions(options?: { skip?: boolean }) {
  return useQuery(GetCreateContactOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useCreateContact() {
  return useGuardedMutation(CreateContactDocument);
}

export function useGetContact(id: string, options?: { skip?: boolean }) {
  return useQuery(GetContactDocument, { variables: { id }, skip: options?.skip });
}

export function useUpdateContact() {
  return useGuardedMutation(UpdateContactDocument);
}
