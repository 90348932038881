import React from 'react';
import { Archive, ContentCopy, Download, Edit, Favorite } from '@mui/icons-material';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useTranslation } from 'react-i18next';

// t('viper::duplicate')
// t('viper::archive')
// t('viper::add-to-favourites')
// t('viper::download')

export const StyledListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    font-size: 14px;
  }
`;

export type ActionMenuItem =
  | {
      id: string;
      label?: string;
      // eslint-disable-next-line @typescript-eslint/ban-types
      icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
      };
      disabled?: boolean;
      divider?: false;
    }
  | {
      id: string;
      divider: true;
    };

export const ACTION_EDIT = {
  id: 'edit',
  icon: Edit,
} as const;
export const ACTION_DUPLICATE = {
  id: 'duplicate',
  icon: ContentCopy,
} as const;
export const ACTION_ARCHIVE = {
  id: 'archive',
  icon: Archive,
} as const;
export const ACTION_FAVOURITE = {
  id: 'add-to-favourites',
  icon: Favorite,
} as const;
export const ACTION_DOWNLOAD = {
  id: 'download',
  icon: Download,
} as const;
export const MENU_DIVIDER = {
  id: 'divider',
  divider: true,
} as const;

export const ActionMenu: React.FC<{
  menuItems: ActionMenuItem[];
  translationNamespace?: string;
  onClick?: (id: string) => void;
  open: boolean;
  onClose: () => void;
  anchorEl?: Element;
}> = ({ menuItems, onClick, translationNamespace = 'viper', open, onClose, anchorEl }) => {
  const { t } = useTranslation(translationNamespace);

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      onClick={(event) => event.stopPropagation()}
    >
      {menuItems.map((menuItem) => {
        if (menuItem.divider) {
          return <Divider key={menuItem.id} />;
        } else {
          const Icon = menuItem.icon;
          return (
            <MenuItem
              key={menuItem.id}
              onClick={(event) => {
                event.stopPropagation();
                onClose();
                onClick?.(menuItem.id);
              }}
              disabled={menuItem.disabled}
            >
              <ListItemIcon>
                <Icon fontSize='small' />
              </ListItemIcon>
              <StyledListItemText>{t(menuItem.label || menuItem.id)}</StyledListItemText>
            </MenuItem>
          );
        }
      })}
    </Menu>
  );
};
