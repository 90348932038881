export const Path = {
  HOME: '/',
  COMPANY_CREATE_PATH: '/companies/create',
  COMPANY_LIST_PATH: '/companies',
  CONTACT_CREATE_PATH: '/contacts/create',
  CONTACT_LIST_PATH: '/contacts',
  COOKIE_POLICY_PATH: '/cookie-policy',
  CUSTOMER_ASSET_CREATE_PATH: '/customer-assets/create',
  CUSTOMER_ASSET_LIST_PATH: '/customer-assets',
  CUSTOMER_ASSET_GRAPH_PATH: '/customer-assets/:id/graph',
  CUSTOMER_ASSET_OVERVIEW_PATH: '/customer-assets/:id/overview',
  CUSTOMER_ASSET_LOGS_PATH: '/customer-assets/:id/logs',
  CUSTOMER_ASSET_REPORT_PATH: '/customer-assets/:id/reports',
  LICENCE_INSIGHTS_PATH: '/licence-insights',
  VIPER_ASSET_CREATE_PATH: '/viper-assets/create',
  VIPER_ASSET_LIST_PATH: '/viper-assets',
  VIPER_ASSET_VIEW_PATH: '/viper-assets/:id',
  VIPER_ASSET_OVERVIEW_PATH: '/viper-assets/:id/overview',
  VIPER_ASSET_LOGS_PATH: '/viper-assets/:id/logs',
  V_LIFE_LICENCE_CREATE_PATH: '/customer-assets/:id/create-licence',
  V_LIFE_REPORT_PENDING_LIST_PATH: '/v-life-reports',
  V_LIFE_REPORT_REVIEW_LIST_PATH: '/v-life-reports/review',
  V_LIFE_REPORT_APPROVE_LIST_PATH: '/v-life-reports/approve',
  V_LIFE_REPORT_PUBLISH_LIST_PATH: '/v-life-reports/publish',
  V_LIFE_REPORT_APPROVE_PATH: '/v-life-reports/approve/:reportFormId',
  V_LIFE_REPORT_CREATE_PATH: '/v-life-reports/create/:channelId',
  V_LIFE_REPORT_PUBLISH_PATH: '/v-life-reports/publish/:reportFormId',
  V_LIFE_REPORT_REVIEW_PATH: '/v-life-reports/review/:reportFormId',
  V_LOG_LIST_PATH: '/v-logs',
  UNAUTHORIZED_PATH: '/unauthorised',
  USER_PROFILE_PATH: '/user-profile',
} as const;

type PathKey = keyof typeof Path;
export type PathValue = typeof Path[PathKey];

/**
 * Builds a path by replacing and parameter placeholders with the
 * parameters passed to this function.
 *
 * e.g.
 * ```
 * buildPath('/project/:projectId/:subProject/details', {
 *   projectId: '123',
 *   subProject: 'stuff'
 * })
 * ```
 * would produce `/project/123/stuff/details`
 */
export function buildPath(
  path: PathValue,
  params?: Record<string, string | undefined>
): string {
  if (!params) {
    return path;
  }
  return Object.keys(params).reduce((prev, current) => {
    return prev.replaceAll(
      new RegExp(`(^|\\/):${current}(/|$)`, 'g'),
      `$1${params[current]}$2`
    );
  }, path);
}
