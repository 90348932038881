import { GetViperAssetQuery } from '~/graphql/generated/asset/graphql';
import { useViperAssetLifecycleTableColumns } from '~/features/viper-asset/hooks/lifecycle-history-table/useViperAssetLifecycleTableColumns';
import { useViperAssetLifecycleDetailsColumns } from '~/features/viper-asset/hooks/lifecycle-history-table/useViperAssetLifecycleDetailColumns';
import { useMemo } from 'react';

export const useViperAssetLifecycleTable = (viperAsset: GetViperAssetQuery['viperAsset']) => {
  const hasHistory = useMemo(
    () => !!viperAsset?.lifecycleEvents.length && viperAsset?.lifecycleEvents.length > 0,
    [viperAsset?.lifecycleEvents.length]
  );

  const { lifecycleHistoryTableColumns } = useViperAssetLifecycleTableColumns(viperAsset);
  const { lifecycleHistoryDetailColumns } = useViperAssetLifecycleDetailsColumns(viperAsset);

  return {
    hasHistory,
    lifecycleHistoryRows: lifecycleHistoryTableColumns,
    lifecycleHistoryDetails: lifecycleHistoryDetailColumns,
  };
};
