import React, { PropsWithChildren } from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { RecoilRoot } from 'recoil';
import { theme } from '../themes/mui';
import { MsalProvider } from '@azure/msal-react';
import { pca } from '../common/msal';
import { ApolloLink, ApolloProvider } from '@apollo/client';
import { MockedProvider, MockedResponse } from '@apollo/client/testing';
import { apolloClient } from './graphql-asset';
import { ActionProvider } from '~/components/Actions/ActionProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CookiesProvider } from 'react-cookie';
import { AppInsightsProvider } from '~/utils/telemetry/AppInsightsProvider';
import { RBACProvider } from '~/authorization/RBACProvider';
import { TitleProvider } from '~/components/TitleProvider/TitleProvider';

const testTheme = createTheme({ ...theme, transitions: { create: () => 'none' } });

export const Wrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <AppInsightsProvider>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={navigator.language}>
        <MsalProvider instance={pca}>
          <ApolloProvider client={apolloClient}>
            <RBACProvider>
              <ThemeProvider theme={testTheme}>
                <CssBaseline />
                <RecoilRoot>
                  <ActionProvider>{children}</ActionProvider>
                </RecoilRoot>
              </ThemeProvider>
            </RBACProvider>
          </ApolloProvider>
        </MsalProvider>
      </LocalizationProvider>
    </AppInsightsProvider>
  );
};

export const IntegrationTestWrapper = ({ children }: PropsWithChildren) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <MockedProvider>
        <RBACProvider>
          <ThemeProvider theme={testTheme}>
            <CssBaseline />
            <RecoilRoot>
              <ActionProvider>
                <TitleProvider>{children}</TitleProvider>
              </ActionProvider>
            </RecoilRoot>
          </ThemeProvider>
        </RBACProvider>
      </MockedProvider>
    </LocalizationProvider>
  );
};

// Isolated from MSAL provider
export const IsolatedTestWrapper: React.FC<
  PropsWithChildren & {
    mocks?: readonly MockedResponse<Record<string, unknown>>[];
    link?: ApolloLink;
  }
> = ({ children, mocks, link }) => {
  return (
    <CookiesProvider
      allCookies={{
        cookiePreferences: {
          performance: true,
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <MockedProvider mocks={mocks} addTypename={false} link={link}>
          <ThemeProvider theme={testTheme}>
            <CssBaseline />
            <RecoilRoot>
              <ActionProvider>{children}</ActionProvider>
            </RecoilRoot>
          </ThemeProvider>
        </MockedProvider>
      </LocalizationProvider>
    </CookiesProvider>
  );
};

export const ThemeTestWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={testTheme}>
        <ActionProvider>{children}</ActionProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};
