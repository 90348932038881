import {
  useCreateEditEntityDialog,
  UseCreateEditEntityProps,
} from '~/features/customer-asset/components/dialogs/useCreateEditEntityDialog';
import { FormDialog } from '~/components/Form/FormDialog/FormDialog';
import { Toast } from '~/components/Toast/Toast';
import { SaveError } from '~/components/Form/SaveError/SaveError';
import { ApolloCache, DefaultContext, OperationVariables } from '@apollo/client';
import { WarningBar } from '~/components/Form/WarningBar/WarningBar';

export function CreateEditEntityDialog<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TGetVariables extends OperationVariables = OperationVariables,
  TMutateVariables extends OperationVariables = OperationVariables,
  TContext extends DefaultContext = DefaultContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TCache extends ApolloCache<any> = ApolloCache<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TOptions = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TEntity = any
>(
  props: UseCreateEditEntityProps<
    TData,
    TGetVariables,
    TMutateVariables,
    TContext,
    TCache,
    TOptions,
    TEntity
  > & {
    /**
     * onClose is called when the dialog should be closed.
     */
    onClose: () => void;
    /**
     * The dialog title
     */
    title: string;
    /**
     * The message that will be displayed if the mutation is successful. `entityName`
     * can be used to customise the message. It is the value that is returned from the `handleCreate`
     * function
     */
    successMessage: (entityName: string | undefined) => string;
    /**
     * The message that will be displayed if the mutation fails.
     */
    errorMessage: string;
    /**
     * This warning message will be displayed at the bottom of the dialog, if it is specified.
     */
    editWarning?: string;
    validate?: (values: Record<string, unknown>) => Record<string, string> | undefined;
    confirmText?: string;
    cancelText?: string;
  }
) {
  const {
    onClose,
    title,
    successMessage,
    errorMessage,
    editWarning,
    validate,
    confirmText,
    cancelText,
    ...rest
  } = props;

  const {
    fields,
    loading,
    mutating: creating,
    saveEntity,
    setMutated: setCreated,
    mutated: created,
    mutateErrorState: createErrorState,
  } = useCreateEditEntityDialog(rest);

  return (
    <>
      <FormDialog
        open={props.open}
        title={title}
        fields={fields}
        onCancel={onClose}
        disabled={loading || creating}
        onSave={async (values) => {
          await saveEntity(values);
          onClose();
        }}
        validate={validate}
        afterContent={
          editWarning && (
            <div
              style={{
                paddingLeft: 6,
              }}
            >
              <WarningBar inline message={editWarning} />
            </div>
          )
        }
        confirmText={confirmText}
        cancelText={cancelText}
      />
      <SaveError errorState={createErrorState}>{errorMessage}</SaveError>
      <Toast
        severity='success'
        open={!!created}
        onClose={() => {
          setCreated(undefined);
        }}
        autoHideDuration={5000}
      >
        {successMessage(created)}
      </Toast>
    </>
  );
}
