import { DocumentNode, OperationVariables, TypedDocumentNode } from '@apollo/client';
import { isOperationDefinitionNode } from '~/graphql/authorization/utils/isOperationDefinitionNode';
import { unwrapType } from '~/graphql/authorization/utils/unwrapType';
import { guardVariable } from '~/graphql/authorization/utils/guardVariable';
import { useRBAC } from '~/authorization/utils/useRBAC';
import { getInputPermissions } from '~/graphql/authorization/utils/getInputPermissions';

/**
 * Guard the variables, removing any which aren't expected for this mutation,
 * or aren't permitted to be sent, based on the user's permissions.
 *
 * This is done by looping and recursing through all the variables, and then looking them up in the `inputPermissions`
 * map to find out their types and what permissions are required.
 *
 * @param variables a map of variables, which should conform to the expected input type for the mutation we are calling.
 * @param mutation information about the query we are making. This includes the names and type names of the variables, but only
 * at the top level.
 * @returns the variables after they have been guarded
 */
export const useGuardVariables = <TData = unknown>(
  mutation: DocumentNode | TypedDocumentNode<TData, OperationVariables>
) => {
  const { userHasPermission } = useRBAC();

  const guardVariables = (
    variables: OperationVariables | undefined
  ): OperationVariables | undefined => {
    if (!variables) {
      return undefined;
    }

    const mutationPermissions = getInputPermissions();
    const newVariables: OperationVariables = {};

    // Find the operation definition in the mutation.
    const operationDefinition = mutation.definitions.find(isOperationDefinitionNode);

    // Loop through the variables that this mutation is expecting and guard
    // each one. If a variable is in `variables` but isn't expected in the
    // mutation then it is dropped.
    operationDefinition?.variableDefinitions?.forEach((expectedVariable) => {
      // Get the name and type of the variable definition
      const expectedVariableTypeName = unwrapType(expectedVariable.type).name.value;
      const expectedVariableName = expectedVariable.variable.name.value;

      // Get the variable that matches this definition.
      const variable = variables[expectedVariableName];

      // If we have the variable, guard it.
      if (variable !== undefined) {
        const guardedValue = guardVariable(
          variable,
          expectedVariableTypeName,
          userHasPermission,
          mutationPermissions
        );
        // If we have a value, add it to the new variables
        if (guardedValue !== undefined) {
          newVariables[expectedVariableName] = guardedValue;
        }
      }
    });

    // Return our new, guarded, variable map.
    return newVariables;
  };

  return {
    guardVariables,
  };
};
