import { matchRoutes, useLocation } from 'react-router-dom';
import { routes } from '~/routes';

export const useFormStatus = () => {
  const location = useLocation();
  const currentPath = matchRoutes(routes, location)?.find(
    (match) => match.pathname === location.pathname
  );
  const routeParts = currentPath?.route?.path?.split('/');
  const formStatus = routeParts?.[routeParts.length - 1];

  return {
    formStatus,
  };
};
