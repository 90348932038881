import { GridColDef } from '@mui/x-data-grid-pro';
import { RBACPermission } from '../permissions';
import { useRBAC } from './useRBAC';

export type GuardedGridColDef = GridColDef & {
  requiredPermissions?: RBACPermission[];
};

/**
 * Returns a function to guard an array of grid column definitions.
 *
 * If the user does not have all the required permissions for a particular
 * column then it will not be included in the result.
 */
export const useGuardColumns = () => {
  const { userHasAllPermissions } = useRBAC();
  const guardColumns = (columns: GuardedGridColDef[]): GridColDef[] =>
    columns
      .filter(
        (column) =>
          !column.requiredPermissions || userHasAllPermissions(column.requiredPermissions)
      )
      .map((column) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { requiredPermissions, ...filteredColumn } = column;
        return filteredColumn;
      });
  return { guardColumns };
};
