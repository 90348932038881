import { useRBAC } from '~/authorization/utils/useRBAC';
import { Field } from '@data-driven-forms/react-form-renderer';

export const useFilterOutNonVisibleFields = () => {
  const { userHasPermission } = useRBAC();

  const filterOutNonVisibleFields = (field: Field) => {
    if (field.hideField === true) {
      return false;
    }

    if (field.readPermission && !userHasPermission([field.readPermission])) {
      return false;
    }

    return true;
  };

  return { filterOutNonVisibleFields };
};
