import { PropsWithChildren, ReactNode } from 'react';
import { PathValue } from '~/routes/paths';
import { RBACPermission } from '~/authorization/permissions';
import { useRBAC } from '~/authorization/utils/useRBAC';
import { useIsRBACLoading } from '~/authorization/utils/useIsRBACLoading';
import { useNavigate } from 'react-router';

export type useRBACValidationFn = keyof ReturnType<typeof useRBAC>;

interface IfPermittedBaseProps extends PropsWithChildren {
  requiredPermissions: RBACPermission[];
  permissionEvaluationMethod?: useRBACValidationFn;
}

interface ElseHideProps extends IfPermittedBaseProps {
  elseRender?: never;
  elseRedirectTo?: never;
}

interface ElseRenderProps extends IfPermittedBaseProps {
  elseRender: ReactNode;
  elseRedirectTo?: never;
}

interface ElseRedirectProps extends IfPermittedBaseProps {
  elseRedirectTo: PathValue;
  elseRender?: never;
}

type IfPermittedProps = ElseHideProps | ElseRenderProps | ElseRedirectProps;

/**
  @function         IfPermitted
  @desccription     Allows you to wrap existing JSX content, and hide / render an alternative /
                    redirect a user if unauthorized. You can optionally pass in either `elseRender`, which
                    renders an alternative if the user is unauthorized, or `elseRedirectTo` which instead
                    redirects the user somewhere else (useful for route guards).

  @param requiredPermissions         The required permissions for the user to see the wrapped content
  @param permissionEvaluationMethod  The name of the evaluation method for the component to use.
                                     Defaults to using `userHasPermission` (a single permission).
  @param elseRender                  Can either populate this *OR* `elseRedirectTo`. Provides a render fallback
                                     if the user is deemed unauthorized to see the wrapped content.
  @param elseRedirectTo              Can either populate this *OR* `elseRender`. Provides a path to redirect the
                                     user to if deemed unauthorized. Guarding routes is the primary use case.

 */
export const IfPermitted = ({
  requiredPermissions,
  permissionEvaluationMethod,
  elseRender,
  elseRedirectTo,
  children,
}: IfPermittedProps) => {
  const isPermittedFunctions = useRBAC();
  const isRBACLoading = useIsRBACLoading();
  const { userHasPermission } = isPermittedFunctions;

  const navigateTo = useNavigate();
  const userPermittedFunction = permissionEvaluationMethod
    ? isPermittedFunctions[permissionEvaluationMethod]
    : userHasPermission;

  const isUserPermitted = userPermittedFunction(requiredPermissions);

  if (isRBACLoading) {
    return <></>;
  }

  if (!isUserPermitted) {
    if (elseRender) {
      return <>{elseRender}</>;
    }

    if (elseRedirectTo) {
      navigateTo(elseRedirectTo);
    }

    return <></>;
  }

  return <>{children}</>;
};
